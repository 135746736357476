import { List, ListItem, ListItemText, Tooltip } from "@mui/material";
import React from "react";
import {
  FaChevronDown,
  FaMousePointer,
  FaPlayCircle,
  FaRegCalendarAlt,
  FaRegClock,
} from "react-icons/fa";
import { RecordingSessionType } from "../__generated__/graphql";
import LoadingAnimation from "./LoadingAnimation";
import ActionPickerForAccordion, {
  TimelineEvent,
} from "./Menus/ActionPickerForAccordion";
import Pagination from "./Pagination";

interface RecordingsListProps {
  recordings: RecordingSessionType[];
  onSelect: (recording: RecordingSessionType) => void;
  page: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  loading: boolean;
  loadingEvents: boolean;
  recordingId?: string;
  timelineEvents: TimelineEvent[];
  onClickEvent: (time: number) => void;
  activeTooltip?: string;
  inactiveTooltip?: string;
  highlightRecordingId?: string;
}

const RecordingsList: React.FC<RecordingsListProps> = ({
  recordings,
  onSelect,
  page,
  totalPages,
  onPageChange,
  loading,
  loadingEvents = false,
  recordingId,
  timelineEvents,
  onClickEvent,
  activeTooltip,
  inactiveTooltip,
  highlightRecordingId,
}) => {
  const formatDuration = (duration: number) => {
    const day = Math.floor(duration / 86400);
    const hour = Math.floor((duration % 86400) / 3600);
    const minute = Math.floor((duration % 3600) / 60);
    const second = duration % 60;

    if (day > 0) {
      return `${day}D ${Math.round(hour)}H`;
    }
    if (hour > 0) {
      return `${hour}H ${Math.round(minute)}M`;
    }
    return `${minute}M ${Math.round(second)}S`;
  };

  return (
    <div className='flex flex-col h-full'>
      <div className='flex-1 overflow-auto'>
        {loading ? (
          <LoadingAnimation text='Loading...' />
        ) : (
          <List>
            {recordings.map((recording) => {
              const durationInSeconds = recording.durationMs
                ? parseInt(recording.durationMs, 10) / 1000
                : 0;

              return (
                <ListItem
                  button
                  key={recording.id}
                  onClick={() => onSelect(recording)}
                  className={
                    highlightRecordingId === recording.id
                      ? "!bg-green-50 hover:!bg-green-100"
                      : ""
                  }
                  disableRipple
                >
                  <ListItemText
                    primary={
                      <div className='flex gap-1 items-center'>
                        <div className='w-5'>
                          <FaPlayCircle
                            size={20}
                            className={
                              recordingId === recording.id
                                ? "text-green-500"
                                : "text-gray-400"
                            }
                          />
                        </div>
                        <Tooltip
                          title={
                            recordingId === recording.id
                              ? activeTooltip
                              : inactiveTooltip
                          }
                          arrow
                        >
                          <div className='truncate text-xs leading-5 font-medium text-blue-600'>
                            Recording {recording.id}
                          </div>
                        </Tooltip>
                      </div>
                    }
                    secondary={
                      <div className='flex flex-col'>
                        <div className='flex gap-4 ml-6 mt-1 items-center text-zinc-700 text-[10px] leading-4'>
                          <div className='flex items-center gap-1'>
                            {recordingId === recording.id && (
                              <FaChevronDown size={12} />
                            )}
                            <div className='text-blue-600 text-xs'>
                              {recording.userActionsCount}
                            </div>
                            <FaMousePointer
                              size={12}
                              className='text-slate-400'
                            />
                          </div>
                          <div className='flex items-center gap-0.5'>
                            <FaRegCalendarAlt
                              size={12}
                              className='text-slate-400'
                            />
                            <div>{recording.formattedStartTimestamp}</div>
                          </div>
                          <div className='flex items-center gap-0.5'>
                            <FaRegClock size={12} className='text-slate-400' />
                            <div>{formatDuration(durationInSeconds)}</div>
                          </div>
                        </div>
                        {recordingId === recording.id &&
                          (loadingEvents ? (
                            <LoadingAnimation
                              text='Loading Events...'
                              size={60}
                            />
                          ) : (
                            <div className='ml-8'>
                              <ActionPickerForAccordion
                                events={timelineEvents}
                                onHoverEvent={() => {}}
                                onClickEvent={onClickEvent}
                              />
                            </div>
                          ))}{" "}
                      </div>
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        )}
      </div>
      <div className='bg-white'>
        <Pagination
          page={page}
          totalPages={totalPages}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  );
};

export default RecordingsList;
