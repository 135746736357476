import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { FaBriefcaseMedical, FaStethoscope } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { CREATE_TRIAGE_MUTATION } from "../../graphql/triage";
import CtaButton from "../Buttons/CtaButton";
import LoadingAnimation from "../LoadingAnimation";
import ContextAltClickMenu from "../Menus/ContextAltClickMenu";
import LogRow from "./LogRow";
import RecordingRow from "./RecordingRow";

export interface LogItem {
  id: string;
  recordingId?: string;
  timestampFormatted: string;
  message: string;
  fields: { [key: string]: string };
}

interface RecordingItem {
  id: string;
  recordingId?: string;
  userId?: string;
  duration: string;
  startTime: string;
  deviceType: "desktop" | "mobile";
  fields: { [key: string]: string };
}

interface RecordListProps {
  logs?: LogItem[];
  recordings?: RecordingItem[];
  isSelectable?: boolean;
  page: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  logsPerPage: number;
  setLogsPerPage: (logsPerPage: number) => void;
  triageAllowed?: boolean;
  loading: boolean;
}

const RecordList: React.FC<RecordListProps> = ({
  logs,
  recordings,
  page,
  totalPages,
  onPageChange,
  logsPerPage,
  setLogsPerPage,
  triageAllowed,
  loading,
}) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectedRecordingIds, setSelectedRecordingIds] = useState<string[]>(
    [],
  );
  const [highlightedIds, setHighlightedIds] = useState<string[]>([]);
  const [highlightedRecordingIds] = useState<string[]>([]);
  // const [highlightedRecordingIds, setHighlightedRecordingIds] = useState<
  //   string[]
  // >([]);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartIndex, setDragStartIndex] = useState<number | null>(null);
  const [, setLastClickedIndex] = useState<number | null>(null);
  // const [lastClickedIndex, setLastClickedIndex] = useState<number | null>(null);
  // const [shiftClickStartIndex, setShiftClickStartIndex] = useState<
  //   number | null
  // >(null);
  const [shiftClickStartIndex] = useState<number | null>(null);
  const [dragStartPos, setDragStartPos] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [dragBoxStyle, setDragBoxStyle] = useState<React.CSSProperties | null>(
    null,
  );
  const [initialHighlightedIds, setInitialHighlightedIds] = useState<string[]>(
    [],
  );
  const [addCmdDragAndSelect, setAddCmdDragAndSelect] = useState<boolean>(true);
  const [contextMenu, setContextMenu] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [inputPage, setInputPage] = useState<string>(page.toString());
  const [warningMessage, setWarningMessage] = useState<string>("");
  const pageEntryWidth = 20 + 10 * totalPages.toString().length;
  const listRef = useRef<HTMLDivElement>(null);
  const [displayOnly, setDisplayOnly] = useState<boolean>(true);
  const [triageEnabled, setTriageEnabled] = useState(false);

  const [createTriage] = useMutation(CREATE_TRIAGE_MUTATION);

  const navigate = useNavigate();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setHighlightedIds([]);
      } else if (event.key === " ") {
        event.preventDefault();
        if (highlightedIds.length > 0) {
          if (highlightedIds.every((id) => selectedIds.includes(id))) {
            // Deselect all highlighted rows
            setSelectedIds((prev) =>
              prev.filter((id) => !highlightedIds.includes(id)),
            );
          } else {
            // Select all highlighted rows
            setSelectedIds((prev) => [
              ...new Set([...prev, ...highlightedIds]),
            ]);
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [highlightedIds, selectedIds]);

  useEffect(() => {
    setTriageEnabled(selectedIds.length > 0);
  }, [selectedIds]);

  useEffect(() => {
    if (warningMessage) {
      const timer = setTimeout(() => {
        setWarningMessage("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [warningMessage]);

  useEffect(() => {
    // Recompute selectedRecordingIds whenever selectedIds changes
    const newSelectedRecordingIds = new Set<string>();

    logs?.forEach((log) => {
      if (selectedIds.includes(log.id) && log.recordingId) {
        newSelectedRecordingIds.add(log.recordingId);
      }
    });

    recordings?.forEach((recording) => {
      if (selectedIds.includes(recording.id) && recording.recordingId) {
        newSelectedRecordingIds.add(recording.recordingId);
      }
    });

    setSelectedRecordingIds(Array.from(newSelectedRecordingIds));
  }, [selectedIds, logs, recordings]);

  const handlePageInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setInputPage(event.target.value);
  };

  const handleTriage = async () => {
    try {
      // Determine which variable to use based on the presence of recordings or logs
      let triageInfoRecordings = null;
      let triageInfoLogs = null;

      // If there are recordings, populate triageInfoRecordings
      if (recordings && recordings.length > 0) {
        triageInfoRecordings = selectedRecordingIds.map((id) => ({
          recordingId: id,
          identifiedProblemWindows: [], // Add any additional data you need here
        }));
      }

      // If there are logs, populate triageInfoLogs
      if (logs && logs.length > 0) {
        // Map selected recording IDs to their associated logRecordIds
        triageInfoLogs = selectedRecordingIds.map((recordingId) => ({
          recordingId: recordingId,
          logRecordIds: logs
            .filter(
              (log) =>
                log.recordingId === recordingId && selectedIds.includes(log.id),
            )
            .map((log) => {
              if (typeof log.id === "string" && log.id.includes("-")) {
                return log.id.split("-")[0];
              }
              return log.id.toString();
            }),
          // TODO - Sibyl launch - uncomment below when id is an integer
          // .map((log) => log.id),
        }));
      }

      const response = await createTriage({
        variables: {
          triageInfoRecordings,
          triageInfoLogs,
          companyId: null, // Adjust this if you have a company ID or remove if not needed
        },
      });
      if (response.data) {
        navigate(`/triage/${response.data.createTriage.id}`);
      }
    } catch (error) {
      console.error("Error creating triage:", error);
    }
  };

  const handlePageInputSubmit = () => {
    let newPage = parseInt(inputPage, 10);
    if (isNaN(newPage) || newPage <= 0) {
      newPage = 1;
      setWarningMessage("Entered page is less than 1. Setting to page 1.");
    } else if (newPage > totalPages) {
      newPage = totalPages;
      setWarningMessage(
        `Entered page is greater than the total pages. Setting to page ${totalPages}.`,
      );
    }
    setInputPage(newPage.toString());
    onPageChange(newPage);
  };

  const handlePageInputKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === "Enter") {
      handlePageInputSubmit();
    }
  };

  const toggleSelect = (id: string) => {
    setSelectedIds((prev) =>
      prev.includes(id)
        ? prev.filter((selectedId) => selectedId !== id)
        : [...prev, id],
    );
  };

  const handleMouseDown = (event: React.MouseEvent, index: number) => {
    if (event.altKey || event.button === 2) {
      return;
    }

    const data = logs || recordings;
    if (!data) return;

    const isMessageArea =
      event.target instanceof HTMLElement &&
      (event.target.classList.contains("log-message") ||
        event.target.closest(".log-message"));

    if (!isMessageArea || event.shiftKey) {
      event.preventDefault();
      setIsDragging(true);
      setDragStartIndex(index);

      const listRect = listRef.current?.getBoundingClientRect();
      const startX =
        event.clientX -
        (listRect?.left || 0) +
        (listRef.current?.scrollLeft || 0);
      const startY =
        event.clientY -
        (listRect?.top || 0) +
        (listRef.current?.scrollTop || 0);

      setDragStartPos({ x: startX, y: startY });

      if (event.metaKey) {
        setInitialHighlightedIds(highlightedIds);
        const itemId = "id" in data[index] ? data[index].id : `${index}`;
        setAddCmdDragAndSelect(!highlightedIds.includes(itemId));
      } else {
        setInitialHighlightedIds([]);
      }

      setDragBoxStyle({
        position: "absolute",
        left: startX,
        top: startY,
        width: 0,
        height: 0,
        backgroundColor: "rgba(0, 120, 215, 0.3)",
        border: "1px solid #0078D7",
        pointerEvents: "none",
        zIndex: 1000,
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setDragBoxStyle(null);
  };

  const handleMouseMove = (event: React.MouseEvent, index: number) => {
    if (isDragging && dragStartIndex !== null && dragStartPos) {
      const listRect = listRef.current?.getBoundingClientRect();
      const currentX =
        event.clientX -
        (listRect?.left || 0) +
        (listRef.current?.scrollLeft || 0);
      const currentY =
        event.clientY -
        (listRect?.top || 0) +
        (listRef.current?.scrollTop || 0);

      const left = Math.min(dragStartPos.x, currentX);
      const top = Math.min(dragStartPos.y, currentY);
      const right = Math.max(dragStartPos.x, currentX);
      const bottom = Math.max(dragStartPos.y, currentY);

      setDragBoxStyle({
        position: "absolute",
        left: left,
        top: top,
        width: right - left,
        height: bottom - top,
        backgroundColor: "rgba(0, 120, 215, 0.3)",
        border: "1px solid #0078D7",
        pointerEvents: "none",
        zIndex: 1000,
      });

      const data = logs || recordings;
      if (!data) return;

      const startIndex = Math.min(dragStartIndex, index);
      const endIndex = Math.max(dragStartIndex, index);
      const newHighlightedIds = data
        .slice(startIndex, endIndex + 1)
        .map((item, idx) => ("id" in item ? item.id : `${startIndex + idx}`));

      if (event.metaKey) {
        if (addCmdDragAndSelect) {
          setHighlightedIds([
            ...new Set([...initialHighlightedIds, ...newHighlightedIds]),
          ]);
        } else {
          setHighlightedIds(
            initialHighlightedIds.filter(
              (id) => !new Set(newHighlightedIds).has(id),
            ),
          );
        }
      } else {
        setHighlightedIds(newHighlightedIds);
      }
    }
  };

  const handleRowClick = (event: React.MouseEvent, index: number) => {
    const isMessageArea =
      event.target instanceof HTMLElement &&
      (event.target.classList.contains("log-message") ||
        event.target.closest(".log-message"));

    if (event.metaKey || event.altKey || event.button === 2) {
      if (event.metaKey || event.altKey) {
        const data = logs || recordings;
        if (!data) return;

        const itemId = "id" in data[index] ? data[index].id : `${index}`;
        setHighlightedIds((prev) =>
          prev.includes(itemId)
            ? prev.filter((id) => id !== itemId)
            : [...prev, itemId],
        );
      }
      return;
    }

    if (!isMessageArea) {
      event.preventDefault();

      if (event.shiftKey && shiftClickStartIndex !== null) {
        const data = logs || recordings;
        if (!data) return;

        const startIndex = Math.min(shiftClickStartIndex, index);
        const endIndex = Math.max(shiftClickStartIndex, index);
        const newHighlightedIds = data
          .slice(startIndex, endIndex + 1)
          .map((item, idx) => ("id" in item ? item.id : `${startIndex + idx}`));
        setHighlightedIds(newHighlightedIds);
      } else {
        toggleExpandRow(index);
      }
      setLastClickedIndex(index);
    }
  };

  const toggleExpandRow = (index: number) => {
    const data = logs || recordings;
    if (!data) return;

    const itemId = "id" in data[index] ? data[index].id : `${index}`;
    const row = document.getElementById(`log-row-${itemId}`);
    if (row) {
      row.classList.toggle("expanded");
    }
  };

  const handleContextMenu = (event: React.MouseEvent, index: number) => {
    event.preventDefault();
    const data = logs || recordings;
    if (!data) return;

    const itemId = "id" in data[index] ? data[index].id : `${index}`;
    if (highlightedIds.includes(itemId)) {
      setContextMenu({ x: event.clientX, y: event.clientY });
    }
  };

  const expandHighlighted = () => {
    highlightedIds.forEach((id) => {
      const row = document.getElementById(`log-row-${id}`);
      if (row && !row.classList.contains("expanded")) {
        row.classList.add("expanded");
      }
    });
  };

  const collapseHighlighted = () => {
    highlightedIds.forEach((id) => {
      const row = document.getElementById(`log-row-${id}`);
      if (row && row.classList.contains("expanded")) {
        row.classList.remove("expanded");
      }
    });
  };

  const selectHighlighted = () => {
    setSelectedIds((prev) => [...new Set([...prev, ...highlightedIds])]);
    setSelectedRecordingIds((prev) => [
      ...new Set([...prev, ...highlightedRecordingIds]),
    ]);
  };

  const deselectHighlighted = () => {
    setSelectedIds((prev) => prev.filter((id) => !highlightedIds.includes(id)));
    setSelectedRecordingIds((prev) =>
      prev.filter((id) => !highlightedRecordingIds.includes(id)),
    );
  };

  const selectAll = () => {
    const data = logs || recordings;
    if (!data) return;

    // Get all ids from the data
    const allIds = data.map((item, idx) => ("id" in item ? item.id : `${idx}`));
    setSelectedIds(allIds);

    // If data is RecordingItem[], filter and map recordingIds
    if (recordings) {
      const allRecordingIds = recordings
        .filter((item: RecordingItem) => item.recordingId !== undefined)
        .map((item) => item.recordingId as string);
      setSelectedRecordingIds(allRecordingIds);
    }
  };

  const deselectAll = () => {
    setSelectedIds([]);
  };

  function isLogItemArray(
    data: (LogItem | RecordingItem)[],
  ): data is LogItem[] {
    return (data as LogItem[])[0]?.id !== undefined;
  }

  const getContextMenuItems = () => {
    const someSelected = selectedIds.length > 0;
    const someNotSelected = highlightedIds.some(
      (id) => !selectedIds.includes(id),
    );
    const data = logs || recordings;
    if (!data) return [];

    let someExpanded = 0;
    let someCollapsed = false;

    if (isLogItemArray(data)) {
      someExpanded = data.filter((item, idx) => {
        const itemId = item.id || `${idx}`;
        return document
          .getElementById(`log-row-${itemId}`)
          ?.classList.contains("expanded");
      }).length;
      someCollapsed = data.length > someExpanded;
    } else {
      someExpanded = data.filter((item, idx) => {
        const itemId = item.userId || `${idx}`;
        return document
          .getElementById(`log-row-${itemId}`)
          ?.classList.contains("expanded");
      }).length;
      someCollapsed = data.length > someExpanded;
    }

    return [
      {
        label: "Select",
        action: selectHighlighted,
        disabled: !someNotSelected,
      },
      {
        label: "Deselect",
        action: deselectHighlighted,
        disabled: !someSelected,
      },
      {
        label: "Expand",
        action: expandHighlighted,
        disabled: !someCollapsed,
      },
      {
        label: "Collapse",
        action: collapseHighlighted,
        disabled: !someExpanded,
      },
    ];
  };

  const closeContextMenu = () => {
    setContextMenu(null);
  };

  if (logs && recordings) {
    throw new Error("Please provide either logs or recordings, but not both.");
  }

  return (
    <div className='relative flex flex-col h-full'>
      {!displayOnly && (
        <div className='fixed top-0 left-0 right-0 bg-white shadow-md p-2 flex justify-between items-center z-50'>
          <div>
            <span className='mr-2'>Highlighted</span>
            <button
              onClick={selectHighlighted}
              disabled={highlightedIds.length === 0}
              className='mr-2 bg-blue-500 text-white px-4 py-2 rounded disabled:opacity-50'
            >
              Select
            </button>
            <button
              onClick={deselectHighlighted}
              disabled={highlightedIds.length === 0}
              className='mr-2 bg-blue-500 text-white px-4 py-2 rounded disabled:opacity-50'
            >
              Deselect
            </button>
          </div>
          <div>
            <button
              onClick={selectAll}
              className='mr-2 bg-green-500 text-white px-4 py-2 rounded'
            >
              Select All
            </button>
            <button
              onClick={deselectAll}
              className='bg-red-500 text-white px-4 py-2 rounded'
            >
              Deselect All
            </button>
          </div>
        </div>
      )}

      {loading ? (
        <LoadingAnimation text={"Loading..."} />
      ) : (
        <div
          className='overflow-y-scroll flex-grow relative'
          ref={listRef}
          onMouseUp={handleMouseUp}
        >
          {dragBoxStyle && <div style={dragBoxStyle}></div>}

          {logs &&
            logs.map((log, index) => (
              <LogRow
                key={log.id}
                id={log.id}
                timestampFormatted={log.timestampFormatted}
                message={log.message}
                fields={log.fields}
                isSelected={selectedIds.includes(log.id)}
                isHighlighted={highlightedIds.includes(log.id)}
                onSelectToggle={() => toggleSelect(log.id)}
                onMouseDown={(e) => handleMouseDown(e, index)}
                onMouseUp={handleMouseUp}
                onMouseMove={(e) => handleMouseMove(e, index)}
                onClick={(e) => handleRowClick(e, index)}
                onContextMenu={(e) => handleContextMenu(e, index)}
                {...{ isSelectable: !displayOnly }}
              />
            ))}

          {recordings &&
            recordings.map((recording, index) => (
              <RecordingRow
                id={recording.id}
                key={recording.userId || index.toString()}
                userId={recording.userId}
                duration={recording.duration}
                startTime={recording.startTime}
                deviceType={recording.deviceType}
                fields={recording.fields}
                isSelected={selectedIds.includes(
                  recording.id || index.toString(),
                )}
                isHighlighted={highlightedIds.includes(
                  recording.id || index.toString(),
                )}
                onSelectToggle={() =>
                  toggleSelect(recording.id || index.toString())
                }
                onMouseDown={(e) => handleMouseDown(e, index)}
                onMouseUp={handleMouseUp}
                onMouseMove={(e) => handleMouseMove(e, index)}
                onClick={(e) => handleRowClick(e, index)}
                onContextMenu={(e) => handleContextMenu(e, index)}
                {...{ isSelectable: !displayOnly }}
              />
            ))}
        </div>
      )}
      {contextMenu && !displayOnly && (
        <ContextAltClickMenu
          position={contextMenu}
          menuItems={getContextMenuItems()}
          onClose={closeContextMenu}
        />
      )}

      {/* Navigation Menu - pinned to the bottom */}
      <Box
        className='absolute bottom-0 left-0 right-0 bg-white border-t-2 border-slate-200'
        p={2}
      >
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          flexGrow={1}
          sx={{ position: "relative" }}
        >
          <Button disabled={page <= 1} onClick={() => onPageChange(page - 1)}>
            Previous
          </Button>
          <Box display='flex' alignItems='center' mx={2}>
            <span>Page </span>
            <Tooltip
              title={warningMessage}
              open={!!warningMessage}
              placement='top'
            >
              <TextField
                value={inputPage}
                onChange={handlePageInputChange}
                onBlur={handlePageInputSubmit}
                onKeyDown={handlePageInputKeyDown}
                size='small'
                inputProps={{
                  style: {
                    textAlign: "center",
                    padding: "0",
                    height: "30px",
                    width: `${pageEntryWidth}px`,
                  },
                }}
                style={{
                  verticalAlign: "middle",
                  margin: "0 8px",
                  height: "30px",
                  width: `${pageEntryWidth}px`,
                }}
              />
            </Tooltip>
            <span> of {totalPages}</span>
          </Box>
          <Select
            value={logsPerPage}
            onChange={(event) => {
              setLogsPerPage(event.target.value as number);
            }}
            size='small'
            style={{ marginLeft: "16px" }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
          <Button
            disabled={page >= totalPages}
            onClick={() => onPageChange(page + 1)}
          >
            Next
          </Button>

          {/* Triage Button Section - positioned slightly to the right of the Next button */}
          {triageAllowed && (
            <Box className='z-2 mx-4'>
              <Box display='flex' alignItems='center'>
                {displayOnly ? (
                  <CtaButton
                    titlePrimary='Examine'
                    titleHoverPrimary='Examining'
                    icon={<FaStethoscope />}
                    bgPrimaryClass='bg-red-500'
                    textPrimaryClass='text-white'
                    bgPrimaryHoverClass='hover:bg-red-100'
                    textPrimaryHoverClass='hover:text-red-500'
                    onClick={() => {
                      setDisplayOnly(false);
                    }}
                    isPrimary={true}
                    additionalClassNames='z-2'
                  />
                ) : (
                  <>
                    <CtaButton
                      titlePrimary='Examining'
                      titleHoverSecondary='Quit'
                      icon={<FaStethoscope />}
                      bgSecondaryClass='bg-red-100'
                      textSecondaryClass='text-red-500'
                      bgSecondaryHoverClass='hover:bg-red-500'
                      textSecondaryHoverClass='hover:text-white'
                      onClick={() => {
                        setDisplayOnly(true);
                      }}
                      isPrimary={false}
                      additionalClassNames='z-2'
                    />
                    <CtaButton
                      titlePrimary='Triage'
                      titleHoverPrimary='Start'
                      icon={<FaBriefcaseMedical />}
                      bgPrimaryClass='bg-green-500'
                      textPrimaryClass='text-white'
                      bgPrimaryHoverClass='hover:bg-green-100'
                      textPrimaryHoverClass='hover:text-green-500'
                      disabled={!triageEnabled}
                      onClick={() => {
                        handleTriage();
                      }}
                      additionalClassNames='z-2'
                    />
                  </>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default RecordList;
