import { identify } from "@sailfish/recorder";
// import { addOrUpdateMetadata, identify } from "@sailfish/recorder";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {
  COOKIE_DOMAIN_OPTIONS,
  isTokenExpired as isTokenExpiredFn,
} from "../utils/auth";

interface AuthContextType {
  isAuthenticated: boolean;
  setAuthenticated: (value: boolean) => void;
  user: UserType | null;
  setUser: (user: UserType | null) => void;
  redirectPath: string;
  setRedirectPath: (path: string) => void;
  isSailfishQAMember: boolean;
  isTokenExpired: boolean;
}

interface UserType {
  name: string;
  picture: string;
  email: string;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<UserType | null>(null);
  const [redirectPath, setRedirectPath] = useState<string>("/");
  const [isSailfishQAMember, setIsSailfishQAMember] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const isTokenExpired = useMemo(() => {
    const token = Cookies.get("jwt");
    return isTokenExpiredFn(token);
  }, [location]);

  useEffect(() => {
    setIsAuthenticated(!isTokenExpired);
    if (!isTokenExpired) {
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        const parsedUser = JSON.parse(storedUser);
        setUser(parsedUser);
        setIsSailfishQAMember(parsedUser.email.endsWith("@sailfishqa.com"));
        identify(parsedUser.email, { foo: "bar", foobar: 3 }, false); // can add traits
        // addOrUpdateMetadata({ parsedUser }); // sends traits only
      }
    } else if (location.pathname !== "/login") {
      localStorage.setItem("redirectAfterLogin", location.pathname);
      Cookies.remove("jwt", COOKIE_DOMAIN_OPTIONS);
      localStorage.removeItem("user");
      setUser(null);
      navigate("/login");
    }
  }, [location, navigate]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setAuthenticated: setIsAuthenticated,
        user,
        setUser,
        redirectPath,
        setRedirectPath,
        isSailfishQAMember,
        isTokenExpired,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
