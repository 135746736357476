import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionSummary,
  Autocomplete,
  TextField,
} from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useMemo, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaBug, FaCalendarAlt, FaInfinity } from "react-icons/fa";
import { HiMiniShieldCheck, HiOutlineShieldExclamation } from "react-icons/hi2";
import { MdNotifications, MdNotificationsOff } from "react-icons/md";
import { RiShipFill } from "react-icons/ri";
import { TbZzz, TbZzzOff } from "react-icons/tb";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { PuffLoader } from "react-spinners";
import { Id, ToastContainer, toast } from "react-toastify";
import {
  ExceptionRecordQueryResultsType,
  RecordingSessionType,
  SnoozeIssueInput,
  useCreateEngineeringTicketMutation,
  useGetEngineeringTicketPlatformIntegrationQuery,
  useGetIssueLazyQuery,
  useSearchUsingQueryQuery,
  useSnoozeIssueMutation,
  useToggleIssueFollowingStatusForUserMutation,
  useToggleIssueResolvedStatusMutation,
  useUpdateIssueMutation,
} from "../__generated__/graphql";
import client from "../apolloClient";
import CtaButton from "../components/Buttons/CtaButton";
import HoverMenuButton from "../components/Buttons/HoverMenuButton";
import ExceptionView from "../components/Exceptions/ExceptionView"; // Imported ExceptionView
import ApplicationPageWithCta from "../components/Layout/ApplicationPageWithCta";
import ExceptionsList from "../components/ListViews/ExceptionsList";
import LoadingAnimation from "../components/LoadingAnimation";
import CtaModal from "../components/Modal/CtaModal";
import RecordingsList from "../components/RecordingsListWithEvents";
import RecordingSummary from "../components/RecordingSummary";
import RRWebPlayerWithTriage from "../components/RRWebPlayerWithTriage";
import { useAdmin } from "../contexts/AdminContext";
import { useAuth } from "../contexts/AuthContext";
import { useCompany } from "../contexts/CompanyContext";
import useContainerWidth from "../hooks/useContainerWidth";
import useUnzippedEvents from "../hooks/useUnzippedEvents";
import LinearLogo from "../images/linearLogo.png";
import {
  INTEGRATIONS,
  LINEAR_PRIORITIES,
  LINEAR_STATUSES,
} from "../utils/constants";
import { AutocompleteOption, HighlightedSection } from "../utils/interfaces";
import WebSocketService from "../utils/websocket";

client.clearStore(); // Clears cached data

const DEFAULT_ITEMS_PER_PAGE = 10;
const PLAYER_HEIGHT_PX = 462;

const IssuePage: React.FC = () => {
  // Refs
  const rrwebPlayerRef = useRef<any>(null);
  const exceptionsListContainerRef = useRef<HTMLDivElement | null>(null); // Ref for the exceptions list container
  const toastIdRef = useRef<Id | null>(null);

  // Router hooks
  const { issueId } = useParams<{ issueId: string }>();
  const { search } = useLocation();
  const navigate = useNavigate();

  // State hooks
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateTicketModalOpen, setIsCreateTicketModalOpen] = useState(false);
  const [issueTitle, setIssueTitle] = useState<string>("");
  const [issueDescription, setIssueDescription] = useState<string>("");
  const [issueTeam, setIssueTeam] = useState("");
  const [issueProject, setIssueProject] = useState("");
  const [issuePriority, setIssuePriority] = useState(0);
  const [issueStatus, setIssueStatus] = useState(1);
  const [recordings, setRecordings] = useState<any[]>([]);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [selectedRecording, setSelectedRecording] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [highlightedSections, setHighlightedSections] = useState<
    HighlightedSection[]
  >([]);
  const [otherSimilarRecordingsPage, setOtherSimilarRecordingsPage] =
    useState(1);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [exceptionsPage, setExceptionsPage] = useState(1);

  const [searchParams] = useSearchParams();
  const exceptionIdParam = searchParams.get("exceptionId"); // Get the exceptionId from the URL

  // Context hooks
  const { selectedCompany } = useCompany();
  const { user } = useAuth();
  const { isAdminEnabled } = useAdmin();

  // Custom hooks
  const { unzippedEvents, timelineEvents, eventsError, loadingEvents } =
    useUnzippedEvents(selectedRecording?.id);
  const { containerRef, containerWidth } = useContainerWidth();

  // Query parameters
  const queryParams = new URLSearchParams(search);
  const recordingIdParam = queryParams.get("recordingId");

  // Apollo queries and mutations
  const [getIssue, { data, loading, error, refetch }] = useGetIssueLazyQuery({
    variables: {
      issueId: issueId ? parseInt(issueId, 10) : 0,
      companyId: selectedCompany
        ? parseInt(selectedCompany.value, 10)
        : undefined,
    },
    client,
    notifyOnNetworkStatusChange: true,
  });

  const hasEngineeringTicket = data?.getIssue?.engineeringTicket?.team;

  const { data: integrationData } =
    useGetEngineeringTicketPlatformIntegrationQuery({
      variables: {
        provider: INTEGRATIONS.LINEAR,
        companyId: selectedCompany
          ? parseInt(selectedCompany.value, 10)
          : undefined,
      },
      onCompleted: (data) => {
        if (data?.getEngineeringTicketPlatformIntegration) {
          const { installed, defaultPriority, defaultProject, defaultTeam } =
            data.getEngineeringTicketPlatformIntegration;

          // Don't set default values if there is already ticket assigned to the issue
          if (!installed || hasEngineeringTicket) {
            return;
          }
          setIssueTeam(defaultTeam || "");
          setIssueProject(defaultProject || "");
          setIssuePriority(defaultPriority || 0);
        }
      },
    });

  const linearProjects: AutocompleteOption[] =
    integrationData?.getEngineeringTicketPlatformIntegration?.projects || [];
  const linearTeams: AutocompleteOption[] =
    integrationData?.getEngineeringTicketPlatformIntegration?.teams || [];
  const linearInstalled =
    integrationData?.getEngineeringTicketPlatformIntegration?.installed;

  const [createEngineeringTicket] = useCreateEngineeringTicketMutation();
  const [updateIssueMutation] = useUpdateIssueMutation();

  const {
    data: exceptions,
    previousData: previousExceptions,
    loading: exceptionsLoading,
  } = useSearchUsingQueryQuery({
    variables: {
      searchEntity: "exception_records",
      searchQuery: `issue__id == ${issueId}`,
      limit: DEFAULT_ITEMS_PER_PAGE,
      offset: (exceptionsPage - 1) * DEFAULT_ITEMS_PER_PAGE,
      companyId: selectedCompany ? parseInt(selectedCompany.value, 10) : 0,
      orderBy: "-timestamp_ms",
    },
  });

  // Exceptions Data
  const handleExceptionSelect = (exceptionId: string) =>
    navigate({
      search: `?exceptionId=${exceptionId}`,
    });

  useEffect(() => {
    if (selectedCompany || !isAdminEnabled) {
      getIssue(); // Execute query when selectedCompany changes
    }
  }, [isAdminEnabled, selectedCompany]);

  // Calculated values
  const calculateTotalPages = (
    totalItems: number,
    itemsPerPage: number,
  ): number => {
    return Math.ceil(totalItems / itemsPerPage);
  };

  const totalPages = calculateTotalPages(
    totalItemsCount,
    DEFAULT_ITEMS_PER_PAGE,
  );

  const exceptionsTotalPages = calculateTotalPages(
    exceptions?.searchUsingQuery?.results?.totalItemsCount || 0,
    DEFAULT_ITEMS_PER_PAGE,
  );

  // Effects
  useEffect(() => {
    if (data && data.getIssue) {
      setIssueTitle(data.getIssue.title!);
      setIssueDescription(data.getIssue.description || "");
      if (data.getIssue.engineeringTicket?.team) {
        setIssueTeam(data.getIssue.engineeringTicket?.team || "");
        setIssueProject(data.getIssue.engineeringTicket?.project || "");
        setIssuePriority(data.getIssue.engineeringTicket?.priority || 0);
        setIssueStatus(data.getIssue.engineeringTicket?.status || 1);
      }

      const allRecordings = data.getIssue.triages.flatMap((triage) => [
        ...triage.triageRecordingData.map((d) => d.recording),
        ...triage.triageLogData.map((d) => d.recording),
        ...triage.otherSimilarRecordings.map((d) => d.recording),
      ]);

      setRecordings(allRecordings);
      setTotalItemsCount(allRecordings.length);

      const primaryTriage =
        data.getIssue.triages.length == 0 ? null : data.getIssue.triages[0];
      const primaryRecording =
        primaryTriage === null
          ? null
          : primaryTriage.triageRecordingData[0]?.recording || null;

      if (primaryTriage === null) return;
      if (primaryRecording === null) return;

      if (recordingIdParam) {
        const recordingExists = allRecordings.some(
          (recording: { id: string }) => recording.id === recordingIdParam,
        );

        if (recordingExists) {
          setSelectedRecording(
            allRecordings.find(
              (recording: { id: string }) => recording.id === recordingIdParam,
            ),
          );
        } else {
          queryParams.delete("recordingId");
          navigate({ search: queryParams.toString() });
          setErrorMessage(
            "The selected recording is not in the set of recordings for the given issue.",
          );
        }
      } else {
        setSelectedRecording(primaryRecording);
        const sections: HighlightedSection[] =
          primaryTriage.triageRecordingData[0]?.timeWindows.flatMap(
            (timeWindow: any) => ({
              startTime: timeWindow.timestampStart,
              endTime: timeWindow.timestampEnd,
              text: 'Marked as "Error" Segment',
              color: "#fcd34d",
            }),
          );

        setHighlightedSections(sections);
      }
    }
  }, [data, recordingIdParam, navigate]);

  // Notify user for status update
  useEffect(() => {
    const handleMessage = (message: MessageEvent) => {
      const data = JSON.parse(message.data);
      if (
        data?.message === "statusUpdate" &&
        (!toastIdRef.current || !toast.isActive(toastIdRef.current))
      ) {
        toastIdRef.current = toast(
          <div className='flex gap-2 text-sm font-medium items-end'>
            <div className='flex-1'>
              Please Refresh the page to get the real Other Similar Recordings
            </div>
            <button
              className='h-8 px-2 shadow rounded-md border'
              onClick={() => refetch()}
            >
              Refresh
            </button>
          </div>,
          { type: "info", className: "w-96" },
        );
      }
    };

    WebSocketService.subscribe(handleMessage);

    return () => {
      WebSocketService.unsubscribe(handleMessage);
    };
  }, [refetch]);

  // Updated toast
  useEffect(() => {
    if (toastIdRef.current) {
      if (loading) {
        toast.update(toastIdRef.current, {
          render: (
            <div className='flex gap-2 text-sm font-medium items-center'>
              <div className='flex-1'>Refreshing data...</div>
              <div className='flex items-center gap-2'>
                <PuffLoader color='#4A90E2' size={24} />
              </div>
            </div>
          ),
          type: "info",
          autoClose: false,
        });
      } else {
        toast.update(toastIdRef.current, {
          render: (
            <div className='text-sm font-medium'>Data has been updated!</div>
          ),
          type: "success",
          autoClose: 3000,
        });
        toastIdRef.current = null;
      }
    }
  }, [loading, toastIdRef]);

  // Event handlers
  const handleSelectRecording = (recording: RecordingSessionType) => {
    // Update the URL with the selected recording while staying on the same page
    navigate(`?recordingId=${recording.id}`, { replace: true });

    // Update the selected recording state
    setSelectedRecording(recording);
  };

  const handlePageChangeOtherSimilarRecordings = (newPage: number) => {
    setOtherSimilarRecordingsPage(newPage);
  };

  const handlePageChangExceptions = (newPage: number) => {
    setExceptionsPage(newPage);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const resetFields = () => {
    setIssueTitle(data?.getIssue?.title || "");
    setIssueDescription(data?.getIssue?.description || "");
    setIssueTeam(
      data?.getIssue?.engineeringTicket?.team ||
        integrationData?.getEngineeringTicketPlatformIntegration?.defaultTeam ||
        "",
    );
    setIssueProject(
      data?.getIssue?.engineeringTicket?.project ||
        integrationData?.getEngineeringTicketPlatformIntegration
          ?.defaultProject ||
        "",
    );
    setIssuePriority(
      data?.getIssue?.engineeringTicket?.priority ||
        integrationData?.getEngineeringTicketPlatformIntegration
          ?.defaultPriority ||
        0,
    );
    setIssueStatus(data?.getIssue?.engineeringTicket?.status || 1);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    resetFields();
  };

  const handleCloseCreateTicketModal = () => {
    setIsCreateTicketModalOpen(false);
    resetFields();
  };

  const isUserFollowingIssue = useMemo(() => {
    if (!user || !data || !data.getIssue || !data.getIssue.followers) {
      return false;
    }
    return data.getIssue.followers.some(
      (follower) => follower.email === user.email,
    );
  }, [user, data]);

  const isIssueResolved = useMemo(() => {
    if (!user || !data || !data.getIssue || !data.getIssue.isResolved) {
      return false;
    }
    return data.getIssue.isResolved;
  }, [user, data]);

  const [toggleFollowIssueMutation] =
    useToggleIssueFollowingStatusForUserMutation({ client });

  const [toggleResolvedStatusMutation] = useToggleIssueResolvedStatusMutation({
    client,
  });

  const [updateIssueSnoozeStatus] = useSnoozeIssueMutation({ client });

  const handleFollowToggle = async () => {
    if (!data?.getIssue?.id) return;
    await toggleFollowIssueMutation({
      variables: {
        input: {
          issueId: Number(data.getIssue.id),
          wantsToFollow: !isUserFollowingIssue,
        },
        companyId: selectedCompany
          ? parseInt(selectedCompany.value, 10)
          : undefined,
      },
    });
    // Refetch the issue data to update the UI
    refetch();
  };

  const handleResolvedToggle = async () => {
    if (!data?.getIssue?.id) return;
    await toggleResolvedStatusMutation({
      variables: {
        input: {
          issueId: Number(data.getIssue.id),
          setResolvedStateTo: !isIssueResolved,
        },
        companyId: selectedCompany
          ? parseInt(selectedCompany.value, 10)
          : undefined,
      },
    });
    // Refetch the issue data to update the UI
    refetch();
  };

  const handleUpdateSnoozeMode = async (
    snoozeMode: "NewRelease" | "Indefinite" | "Until" | "Disable",
    snoozedUntil?: string, // ISO string for 'Until' mode
  ) => {
    const issueId = data?.getIssue?.id;
    if (!issueId) return;

    console.log(`Snooze mode: ${snoozeMode}`);

    const input: SnoozeIssueInput = {
      issueId: Number(issueId),
      snoozedMode: snoozeMode,
      snoozedPermanently: snoozeMode === "Indefinite",
      ...(snoozeMode === "Until" && snoozedUntil ? { snoozedUntil } : {}),
    };

    await updateIssueSnoozeStatus({
      variables: {
        input,
        companyId: selectedCompany
          ? parseInt(selectedCompany.value, 10)
          : undefined,
      },
    });

    // Refetch the issue data to update the UI
    refetch();
  };

  const handleCreateTicket = async () => {
    if (!issueTitle.trim()) {
      console.error("'Ticket Name' field is required.");
      return;
    }

    try {
      // Execute the createTicket mutation
      await createEngineeringTicket({
        variables: {
          input: {
            issueId: data?.getIssue?.id || "",
            title: issueTitle.trim(),
            description: issueDescription.trim(),
            team: issueTeam,
            project: issueProject,
            priority: issuePriority,
          },
          companyId: selectedCompany
            ? parseInt(selectedCompany.value, 10)
            : undefined,
        },
      });

      handleCloseCreateTicketModal();

      // Re-fetch issue data
      refetch();
    } catch (error) {
      console.error("Error creating ticket:", error);
    }
  };

  const handleUpdateIssue = async () => {
    if (!issueTitle.trim() || !issueDescription.trim()) {
      console.error("Both 'Issue Name' and 'Description' fields are required.");
      return;
    }

    try {
      // Execute the updateIssue mutation
      await updateIssueMutation({
        variables: {
          input: {
            id: data?.getIssue?.id,
            title: issueTitle.trim(),
            description: issueDescription.trim(),
          },
          teamId: issueTeam,
          projectId: issueProject,
          priority: issuePriority,
          status: issueStatus,
          companyId: selectedCompany
            ? parseInt(selectedCompany.value, 10)
            : undefined,
        },
      });

      handleCloseModal();

      // Re-fetch issue data
      refetch();
    } catch (error) {
      console.error("Error updating issue:", error);
    }
  };

  // Early returns after all hooks
  if ((loading && !toastIdRef) || (!data && !error))
    return <LoadingAnimation text='Loading issue details...' />;
  if (error) return <div>Error loading issue details</div>;

  // Variables dependent on data
  const pageName = `Issues > #${issueId}: ${data?.getIssue?.title}`;

  const specificDateSubMenu = (
    <div className='m-6'>
      <div className='flex flex-col items-center'>
        <div className='flex items-start space-x-4'>
          {" "}
          {/* Flexbox for date and time pickers */}
          <DatePicker
            showTimeSelect
            dateFormat='MMMM d, yyyy h:mm aa'
            selected={selectedDate}
            onChange={(date: Date | null) => {
              setSelectedDate(date);
              // if (date) {
              //   handleUpdateSnoozeMode("Until", date.toISOString());
              // }
            }}
            timeIntervals={15}
            // timeCaption='Time'
            inline
            calendarClassName='react-datepicker-custom' // Adding a custom class for styling
          />
        </div>

        {/* Additional Snooze Button */}
        <button
          onClick={() => {
            if (selectedDate) {
              handleUpdateSnoozeMode("Until", selectedDate.toISOString());
            }
          }}
          className='mt-4 px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75'
        >
          Snooze
        </button>
      </div>
    </div>
  );

  const snoozeIcon = (() => {
    switch (data?.getIssue?.snoozedMode) {
      case "NewRelease":
        return <RiShipFill />;
      case "Until":
        return <FaCalendarAlt />;
      case "Indefinite":
        return <FaInfinity />;
      case "Disabled":
      default:
        return <TbZzz />;
    }
  })();

  const defaultContent = "-";

  // TODO Move Triage calculated data to the backend - SAI-848
  const occurrence =
    selectedRecording && data?.getIssue?.triages
      ? data.getIssue.triages.find(
          ({ recordingSession }) =>
            recordingSession?.pk === selectedRecording.id,
        )
      : data?.getIssue;

  const occurrenceData = [
    {
      title: "Change/24h",
      content: occurrence?.change24h || defaultContent,
    },
    {
      title: "Last Seen",
      content: occurrence?.lastSeen
        ? DateTime.fromISO(occurrence.lastSeen, {
            zone: "utc",
          }).toRelative()
        : defaultContent,
    },
    {
      title: "Severity",
      content: occurrence?.severity || defaultContent,
    },
    {
      title: "Users Affected",
      content: occurrence?.affectedPercentageUsers || defaultContent,
    },
    {
      title: "Devices",
      content:
        occurrence?.devices?.map(
          (device, index) =>
            device && (
              <div key={index}>
                {device.device?.brand || "Unknown"} {device.device?.type || ""}
              </div>
            ),
        ) || defaultContent,
    },
  ];

  const currentRecordingIndex = recordings.findIndex(
    ({ id }) => id === selectedRecording?.id,
  );
  const isFirstRecording = currentRecordingIndex === 0;
  const isLastRecording = currentRecordingIndex === recordings.length - 1;

  const handleBackwardRecording = () => {
    if (isFirstRecording) {
      handlePageChangeOtherSimilarRecordings(otherSimilarRecordingsPage - 1);
    } else {
      handleSelectRecording(recordings[currentRecordingIndex - 1]);
    }
  };

  const handleForwardRecording = () => {
    if (isLastRecording) {
      handlePageChangeOtherSimilarRecordings(otherSimilarRecordingsPage + 1);
    } else {
      handleSelectRecording(recordings[currentRecordingIndex + 1]);
    }
  };

  const backwardRecordingDisabled =
    isFirstRecording && otherSimilarRecordingsPage === 1;
  const forwardRecordingDisabled =
    isLastRecording && otherSimilarRecordingsPage === totalPages;

  //  TODO - split this into separate component per engineering ticket integration
  const createTicketFields = [
    <Autocomplete
      key='Team'
      options={linearTeams}
      getOptionLabel={(option) => option.name}
      value={linearTeams.find(({ id }) => id == issueTeam) || null}
      onChange={(event, value) => setIssueTeam(value?.id || "")}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField {...params} label='Team' variant='outlined' />
      )}
    />,
    <Autocomplete
      key='Project'
      options={linearProjects}
      getOptionLabel={(option) => option.name}
      value={linearProjects.find(({ id }) => id == issueProject) || null}
      onChange={(event, value) => setIssueProject(value?.id || "")}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField {...params} label='Project' variant='outlined' />
      )}
    />,
    <Autocomplete
      key='Priority'
      disableClearable
      options={LINEAR_PRIORITIES}
      getOptionLabel={(option) => option.name} // Maps `name` for display
      value={LINEAR_PRIORITIES.find(({ value }) => value == issuePriority)}
      onChange={(event, newValue) => setIssuePriority(newValue?.value || 0)}
      isOptionEqualToValue={(option, value) => option.value === value.value} // Compares `value`
      renderInput={(params) => (
        <TextField {...params} label='Priority' variant='outlined' />
      )}
    />,
  ];

  const updateTicketFields = [
    ...createTicketFields,
    <Autocomplete
      key='Status'
      disableClearable
      options={LINEAR_STATUSES}
      getOptionLabel={(option) => option.name} // Maps `name` for display
      value={LINEAR_STATUSES.find(({ value }) => value == issueStatus)}
      onChange={(event, newValue) => setIssueStatus(newValue?.value || 0)}
      isOptionEqualToValue={(option, value) => option.value === value.value} // Compares `value`
      renderInput={(params) => (
        <TextField {...params} label='Status' variant='outlined' />
      )}
    />,
  ];

  const exceptionsData = exceptionsLoading ? previousExceptions : exceptions;
  const exceptionsList =
    (exceptionsData?.searchUsingQuery as ExceptionRecordQueryResultsType)
      ?.results?.items || [];

  return (
    <ApplicationPageWithCta
      pageName={pageName}
      helpLink='https://docs.sailfishqa.com/'
      helpTooltip='Click here to learn about Issues.'
      CtaButton={
        <div className='flex gap-2'>
          {linearInstalled && !hasEngineeringTicket && (
            <button
              className='h-8 w-64 flex items-center bg-white text-black px-4 py-2 shadow rounded-md gap-1 text-sm font-medium'
              onClick={() => setIsCreateTicketModalOpen(true)}
            >
              <img src={LinearLogo} width={32} alt='Linear Logo' />
              Create Engineering Ticket
            </button>
          )}
          <CtaButton
            titlePrimary='Edit Issue'
            icon={<FaBug />}
            bgPrimaryClass='bg-blue-500'
            textPrimaryClass='text-white'
            bgPrimaryHoverClass='hover:bg-blue-600'
            textPrimaryHoverClass='hover:text-white'
            onClick={handleOpenModal}
            showOutlineHoverPrimary={false}
            additionalClassNames='!px-2.5 h-8 rounded-md text-sm leading-6 font-medium shadow-md'
            additionalWidth='4px'
            startIcon
          />
        </div>
      }
    >
      <div className='flex justify-between items-center pb-4'>
        <span className='font-medium'>{data?.getIssue?.description}</span>
        <div className='flex items-center space-x-4'>
          <div className='flex items-center justify-end space-x-4'>
            <CtaButton
              titlePrimary='Not Following'
              titleHoverPrimary='Follow'
              titleSecondary='Following'
              titleHoverSecondary='Unfollow'
              bgPrimaryClass='bg-amber-100'
              bgSecondaryClass='bg-amber-300'
              bgPrimaryHoverClass='bg-amber-300'
              bgSecondaryHoverClass='bg-amber-100'
              textPrimaryClass='font-semibold text-slate-400'
              textSecondaryClass='font-bold text-slate-700'
              textPrimaryHoverClass='font-bold text-slate-700'
              textSecondaryHoverClass='font-semibold ext-slate-400'
              iconPrimary={<MdNotificationsOff />}
              iconPrimaryHover={<MdNotifications />}
              iconSecondary={<MdNotifications />}
              iconSecondaryHover={<MdNotificationsOff />}
              isPrimary={!isUserFollowingIssue}
              onClick={handleFollowToggle}
              additionalClassNames='!px-2.5 h-8 rounded-md text-sm leading-6 font-medium shadow-md'
            />

            <HoverMenuButton
              titlePrimary='Snooze'
              titleHoverPrimary='Snooze Until'
              titleSecondary='Snoozed until'
              titleHoverSecondary='Modify Snooze'
              icon={snoozeIcon}
              items={[
                {
                  title: "Next release",
                  onClick: () => handleUpdateSnoozeMode("NewRelease"),
                  icon: <RiShipFill />,
                  selected: data?.getIssue?.snoozedMode === "NewRelease",
                  hoveredNotSelectedClassName:
                    "hover:bg-sky-200 hover:text-slate-700 hover:font-semibold",
                  hoveredSelectedClassName: "bg-blue-500 text-white font-bold",
                  notHoveredNotSelectedClassName: "bg-white text-slate-700",
                  notHoveredSelectedClassName:
                    "bg-blue-500 text-white font-bold",
                },
                {
                  title: "Specific Date/Time",
                  icon: <FaCalendarAlt />,
                  selected: data?.getIssue?.snoozedMode === "Until",
                  hoveredNotSelectedClassName:
                    "hover:bg-sky-200 hover:text-slate-700 hover:font-semibold",
                  hoveredSelectedClassName: "bg-sky-400 text-white font-bold",
                  notHoveredNotSelectedClassName: "bg-white text-slate-700",
                  notHoveredSelectedClassName:
                    "bg-blue-500 text-white font-bold",
                  subMenu: specificDateSubMenu,
                  subMenuPosition: "left",
                },
                {
                  title: "End of Time",
                  onClick: () => handleUpdateSnoozeMode("Indefinite"),
                  icon: <FaInfinity />,
                  selected: data?.getIssue?.snoozedMode === "Indefinite",
                  hoveredNotSelectedClassName:
                    "hover:bg-sky-200 hover:text-slate-700 hover:font-semibold",
                  hoveredSelectedClassName: "bg-blue-500 text-white font-bold",
                  notHoveredNotSelectedClassName: "bg-white text-slate-700",
                  notHoveredSelectedClassName:
                    "bg-blue-500 text-white font-bold",
                },
                ...(data?.getIssue?.snoozedMode !== "Disable"
                  ? [
                      {
                        title: "Unsnooze",
                        onClick: () => handleUpdateSnoozeMode("Disable"),
                        icon: <TbZzzOff />,
                        selected: data?.getIssue?.snoozedMode === "Disable",
                        hoveredNotSelectedClassName:
                          "hover:bg-sky-200 hover:text-slate-700 hover:font-semibold",
                        notHoveredNotSelectedClassName:
                          "bg-white text-slate-700",
                      },
                    ]
                  : []),
              ]}
              bgPrimaryClass='bg-gray-300'
              bgPrimaryHoverClass='bg-gray-500'
              bgSecondaryClass='bg-gray-500'
              bgSecondaryHoverClass='bg-gray-500'
              textPrimaryClass='font-semibold text-slate-700'
              textPrimaryHoverClass='text-white'
              textSecondaryClass='text-white'
              textSecondaryHoverClass='text-white'
              hideDelay={500}
              isPrimary={data?.getIssue?.snoozedMode === "Disable"}
              additionalClassNames='!px-2.5 h-8 rounded-md text-sm leading-6 font-medium shadow-md'
            />

            <CtaButton
              titlePrimary='Unresolved'
              titleHoverPrimary='Resolve'
              titleSecondary='Resolved'
              titleHoverSecondary='Unresolve'
              bgPrimaryClass='bg-green-100'
              bgSecondaryClass='bg-green-500'
              bgPrimaryHoverClass='bg-green-500'
              bgSecondaryHoverClass='bg-green-100'
              textPrimaryClass='font-semibold text-slate-800'
              textSecondaryClass='text-slate-50'
              textPrimaryHoverClass='font-bold text-slate-50'
              textSecondaryHoverClass='text-slate-500'
              outlinePrimaryClass='outline outline-2 outline-offset-0 outline-slate-400'
              outlineSecondaryClass='outline outline-2 outline-offset-0 outline-black'
              outlinePrimaryHoverClass='outline outline-2 outline-offset-0 outline-slate-800'
              outlineSecondaryHoverClass='outline outline-2 outline-offset-0 outline-black'
              showOutlinePrimary={true}
              showOutlineSecondary={true}
              showOutlineHoverPrimary={true}
              showOutlineHoverSecondary={true}
              iconPrimary={<HiOutlineShieldExclamation size={20} />}
              iconPrimaryHover={<HiMiniShieldCheck size={20} />}
              iconSecondary={<HiMiniShieldCheck size={20} />}
              iconSecondaryHover={<HiOutlineShieldExclamation size={20} />}
              isPrimary={!isIssueResolved}
              onClick={handleResolvedToggle}
              additionalClassNames='!px-2.5 h-8 rounded-md text-sm leading-6 font-medium shadow-md'
            />
          </div>
        </div>
      </div>
      {isModalOpen && (
        <CtaModal
          title='Edit Issue'
          closeModal={handleCloseModal}
          fields={[
            {
              label: "Issue Title",
              type: "input",
              defaultValue: issueTitle,
              placeholderText: "Enter a title for the issue",
              onChange: (
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
              ) => setIssueTitle(e.target.value),
            },
            {
              label: "Description",
              type: "textarea",
              defaultValue: issueDescription,
              placeholderText: "Issue description...",
              onChange: (
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
              ) => setIssueDescription(e.target.value),
            },
          ]}
          buttons={[
            {
              button: (
                <CtaButton
                  titlePrimary='Cancel'
                  bgPrimaryClass='bg-gray-300'
                  textPrimaryClass='text-secondary'
                  bgPrimaryHoverClass='hover:bg-gray-500'
                  textPrimaryHoverClass='hover:text-primary-dark'
                  outlinePrimaryHoverClass='hover:outline hover:outline-2 hover:outline-offset-0 hover:outline-gray-800'
                  additionalClassNames='w-full'
                  onClick={handleCloseModal}
                />
              ),
              width: 50,
            },
            {
              button: (
                <CtaButton
                  titlePrimary='Save'
                  additionalClassNames='w-full font-semibold'
                  onClick={handleUpdateIssue}
                />
              ),
              width: 50,
            },
          ]}
          additionalFields={
            linearInstalled && hasEngineeringTicket ? updateTicketFields : []
          }
        />
      )}

      {isCreateTicketModalOpen && (
        <CtaModal
          title='Create Engineering Ticket'
          closeModal={handleCloseCreateTicketModal}
          fields={[
            {
              label: "Ticket Title",
              type: "input",
              defaultValue: issueTitle,
              placeholderText: "Enter a title for the ticket",
              onChange: (
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
              ) => setIssueTitle(e.target.value),
            },
            {
              label: "Description",
              type: "textarea",
              defaultValue: issueDescription,
              placeholderText: "Ticket description...",
              onChange: (
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
              ) => setIssueDescription(e.target.value),
            },
          ]}
          buttons={[
            {
              button: (
                <CtaButton
                  titlePrimary='Cancel'
                  bgPrimaryClass='bg-gray-300'
                  textPrimaryClass='text-secondary'
                  bgPrimaryHoverClass='hover:bg-gray-500'
                  textPrimaryHoverClass='hover:text-primary-dark'
                  outlinePrimaryHoverClass='hover:outline hover:outline-2 hover:outline-offset-0 hover:outline-gray-800'
                  additionalClassNames='w-full'
                  onClick={handleCloseCreateTicketModal}
                />
              ),
              width: 50,
            },
            {
              button: (
                <CtaButton
                  titlePrimary='Save'
                  additionalClassNames='w-full font-semibold'
                  onClick={handleCreateTicket}
                />
              ),
              width: 50,
            },
          ]}
          additionalFields={createTicketFields}
        />
      )}

      {errorMessage && <div className='error'>{errorMessage}</div>}
      <div
        className={`relative flex gap-4 ${
          recordings.length ? "" : "overflow-hidden"
        }`}
      >
        <div className='flex flex-[5] flex-col max-h-full min-w-0'>
          {recordings.length ? (
            <>
              <div ref={containerRef}>
                <div className='h-full'>
                  <RRWebPlayerWithTriage
                    id={selectedRecording?.id}
                    ref={rrwebPlayerRef}
                    events={unzippedEvents}
                    timelineEvents={timelineEvents}
                    highlightedSections={highlightedSections}
                    width={containerWidth - 16}
                    height={PLAYER_HEIGHT_PX}
                    containerWidth={containerWidth}
                    eventsError={eventsError}
                    loadingEvents={loadingEvents}
                    handleBackwardRecording={handleBackwardRecording}
                    handleForwardRecording={handleForwardRecording}
                    backwardRecordingDisabled={backwardRecordingDisabled}
                    forwardRecordingDisabled={forwardRecordingDisabled}
                    hideTriageButton
                  />
                </div>
              </div>
              <RecordingSummary recordingId={selectedRecording?.id} />
            </>
          ) : (
            !!data?.getIssue?.exceptions?.length && (
              // Display ExceptionView if there are no recordings
              <ExceptionView
                {...(data.getIssue.exceptions.find(
                  ({ id }) => id === exceptionIdParam,
                ) || data.getIssue.exceptions[0])}
              />
            )
          )}
        </div>
        <div className='flex flex-[2] flex-col gap-2 max-h-full min-w-0'>
          <Accordion
            className='!shadow-md border border-gray-200 !rounded !m-0 flex flex-col p-2.5 overflow-auto min-h-14'
            defaultExpanded
            classes={{ expanded: "min-h-32" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1-content'
              id='panel1-header'
              className='text-sm font-semibold !min-h-8'
              classes={{ content: "!my-0" }}
            >
              Occurrence Info
            </AccordionSummary>
            <div className='grid grid-cols-2 gap-4 p-4 text-sm leading-[18px]'>
              {occurrenceData.map(({ title, content }) => (
                <div
                  key={title}
                  className='border border-sky-200 rounded-md shadow-md p-2'
                >
                  <div>{title}</div>
                  <div className='font-bold'>{content}</div>
                </div>
              ))}
            </div>
          </Accordion>
          <Accordion
            ref={exceptionsListContainerRef}
            className='!shadow-md border border-gray-200 !rounded !m-0 flex flex-col p-2.5 overflow-auto'
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel2-content'
              id='panel2-header'
              className='text-sm font-semibold !min-h-8'
              classes={{ content: "!my-0" }}
            >
              Other Occurrences
            </AccordionSummary>
            {recordings.length ? (
              <RecordingsList
                recordings={recordings}
                onSelect={handleSelectRecording}
                page={otherSimilarRecordingsPage}
                totalPages={totalPages}
                onPageChange={handlePageChangeOtherSimilarRecordings}
                loading={loading}
                loadingEvents={loadingEvents}
                recordingId={selectedRecording?.id}
                timelineEvents={timelineEvents}
                activeTooltip='Back to Recording Page'
                inactiveTooltip='Go to Recording Page'
                onClickEvent={(time) => {
                  // Seek to the time in the player without changing its play state
                  if (rrwebPlayerRef.current) {
                    rrwebPlayerRef.current.seekToTime(time);
                  }
                }}
              />
            ) : (
              <ExceptionsList
                containerRef={exceptionsListContainerRef}
                exceptions={exceptionsList}
                page={exceptionsPage}
                selectedException={exceptionIdParam || exceptionsList[0]?.id}
                totalPages={exceptionsTotalPages}
                onPageChange={handlePageChangExceptions}
                onSelect={handleExceptionSelect}
              />
            )}
          </Accordion>
        </div>
      </div>
      <ToastContainer />
    </ApplicationPageWithCta>
  );
};

export default IssuePage;
