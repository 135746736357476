import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Fragment, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ApplicationPage from "../components/ApplicationPage";
import Table from "../components/Table";
import { useCompany } from "../contexts/CompanyContext";
import {
  TriageIssueType,
  useGetFieldValuesQuery,
  useSearchUsingQueryQuery,
} from "../__generated__/graphql";
import formatRelativeTime from "../utils/dateFns";

const DEFAULT_ITEMS_PER_PAGE = 25;
const COLUMNS = [
  { id: "id", label: "Issue ID" },
  {
    id: "createdBy",
    label: "Created",
    format: () => "Manually",
  },
  {
    id: "issues",
    label: "Issues",
    format: (value: TriageIssueType[]) => (
      <div className='flex gap-x-1'>
        {value.map(({ id, title }, index) => (
          <Fragment key={id}>
            <NavLink
              to={`/issues/${id}`}
              className='font-medium text-blue-600 dark:text-blue-500 hover:underline'
              onClick={(e) => e.stopPropagation()}
            >
              {title}
            </NavLink>
            {index < value.length - 1 && (
              <span className='text-gray-500'>|</span>
            )}
          </Fragment>
        ))}
      </div>
    ),
  },
  {
    id: "createdAt",
    label: "Created At",
    format: (value: string) => formatRelativeTime(value),
  },
];

interface Filters {
  creator: string | null;
  hasIssue: string | null;
}

const getSearchQuery = ({ creator, hasIssue }: Filters) => {
  let searchQuery = "";
  if (creator) {
    searchQuery += `created_by__email == "${creator}"`;
  }
  if (hasIssue) {
    searchQuery += `${searchQuery ? " && " : ""}issues__id ${
      hasIssue === "Yes" ? "!=" : "=="
    } Null`;
  }
  return searchQuery;
};

const TriagesPage = () => {
  const { selectedCompany } = useCompany();
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_ITEMS_PER_PAGE);
  const [results, setResults] = useState<any[]>([]);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [filters, setFilters] = useState<Filters>({
    creator: null,
    hasIssue: null,
  });
  const navigate = useNavigate();

  const { data } = useGetFieldValuesQuery({
    variables: {
      searchEntity: "Triages",
      fieldName: "created_by__email",
      ...(selectedCompany && {
        companyId: parseInt(selectedCompany.value, 10),
      }),
    },
  });

  const creators = data?.getFieldValues || [];

  useSearchUsingQueryQuery({
    variables: {
      searchEntity: "triages",
      searchQuery: getSearchQuery(filters),
      limit: itemsPerPage,
      offset: page * itemsPerPage,
      companyId: selectedCompany ? parseInt(selectedCompany.value, 10) : 0,
      orderBy: "-created_at",
    },
    onCompleted: (data) => {
      const results = data?.searchUsingQuery?.results.items || [];
      const totalItemsCount =
        data?.searchUsingQuery?.results.totalItemsCount || 0;
      setResults(results);
      setTotalItemsCount(totalItemsCount);
    },
  });

  const handleRowClick = (id: string) => {
    navigate(`/triage/${id}`, {
      state: { from: "Triages Page" },
    });
  };

  return (
    <ApplicationPage pageName='Triages'>
      <div className='h-[calc(100%-44px)]'>
        <div className='flex gap-4 pb-4'>
          <Autocomplete
            className='flex-1 min-w-80'
            options={creators}
            value={filters.creator}
            onChange={(_, value) =>
              setFilters((prev) => ({ ...prev, creator: value }))
            }
            renderInput={(params) => (
              <TextField {...params} label='Creator' variant='outlined' />
            )}
          />
          <Autocomplete
            disabled
            className='flex-1'
            options={["Yes", "No"]}
            value={filters.hasIssue}
            onChange={(_, value) =>
              setFilters((prev) => ({ ...prev, hasIssue: value }))
            }
            renderInput={(params) => (
              <TextField {...params} label='Has Issue' variant='outlined' />
            )}
          />
        </div>
        <div className='h-[calc(100%-72px)]'>
          <Table
            columns={COLUMNS}
            count={totalItemsCount}
            idKey='id'
            page={page}
            rows={results}
            rowsPerPage={itemsPerPage}
            handleChangeRowsPerPage={setItemsPerPage}
            onClick={handleRowClick}
            setPage={setPage}
          />
        </div>
      </div>
    </ApplicationPage>
  );
};

export default TriagesPage;
