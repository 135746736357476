import React, { useState } from "react";
import { AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai";
import { BsGraphUp } from "react-icons/bs";
import { FaCheckCircle, FaCircle } from "react-icons/fa";
import { IoEyeOutline } from "react-icons/io5";

interface LogRowProps {
  id: string;
  timestampFormatted: string;
  message: string;
  fields: { [key: string]: string };
  isSelected: boolean;
  isHighlighted?: boolean;
  isSelectable?: boolean;
  onSelectToggle: () => void;
  onClick?: (event: React.MouseEvent) => void;
  onMouseDown?: (event: React.MouseEvent) => void;
  onMouseUp?: (event: React.MouseEvent) => void;
  onMouseMove?: (event: React.MouseEvent) => void;
  onContextMenu?: (event: React.MouseEvent) => void;
}

const LogRow: React.FC<LogRowProps> = ({
  id,
  timestampFormatted,
  message,
  fields,
  isSelected,
  isHighlighted = false,
  isSelectable = true,
  onSelectToggle,
  onClick,
  onMouseDown,
  onMouseUp,
  onMouseMove,
  onContextMenu,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = (event: React.MouseEvent) => {
    if (event.metaKey || event.altKey || event.button === 2) {
      return; // Prevent expansion/contraction on CMD+Click, Alt+Click, and Right-Click
    }
    event.stopPropagation(); // Prevent the row from being highlighted/unhighlighted
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      id={`log-row-${id}`}
      className={`relative cursor-pointer group ${
        isHighlighted
          ? "bg-primary-verylight hover:bg-primary-light"
          : "hover:bg-primary-light"
      } ${isExpanded ? "expanded" : ""}`} // Apply "expanded" class conditionally
      onClick={(e) => {
        if (onClick) onClick(e);
        toggleExpand(e);
      }}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
      onContextMenu={onContextMenu}
      role='button'
      tabIndex={0}
    >
      <div className='p-4 flex items-center relative'>
        {/* Timeline and Select Button */}
        <div className='absolute left-4 top-0 bottom-0 flex items-center'>
          <div className='w-0.5 bg-gray-400 h-full relative flex items-center'>
            {isSelectable ? (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onSelectToggle();
                }}
                className='absolute left-1/2 transform -translate-x-1/2 w-5 h-5 bg-transparent rounded-full flex items-center justify-center'
              >
                {isSelected ? (
                  <FaCheckCircle
                    className='text-[#1F75FE] bg-white rounded-full'
                    style={{ backgroundColor: "white" }}
                  />
                ) : (
                  <FaCircle
                    className='text-white bg-white rounded-full'
                    style={{
                      border: "1px solid gray",
                      boxSizing: "border-box",
                    }}
                  />
                )}
              </button>
            ) : (
              <FaCircle
                className='absolute left-1/2 transform -translate-x-1/2 text-black fill-slate-500'
                style={{ fontSize: "50%" }}
              />
            )}
          </div>
        </div>

        {/* Row Content */}
        <div className='flex items-center flex-grow pl-8'>
          <div>
            <span className='text-sm text-gray-600 dark:text-gray-400'>
              {timestampFormatted}
            </span>
            <span className='text-sm font-medium dark:text-gray-200 ml-2 log-message'>
              {message}
            </span>
          </div>
        </div>
      </div>

      {/* Expanded Section */}
      <div
        className={`expanded-content ${isExpanded ? "block" : "hidden"} ${
          isHighlighted ? "bg-primary-verylight" : ""
        }`}
      >
        {/* Content to show when expanded */}
        <div className='relative dark:bg-gray-900 group-hover:bg-primary-light'>
          {/* Vertical continuation line */}
          <div className='absolute left-4 top-0 bottom-0 w-0.5 bg-gray-400'></div>
          {/* Boxed table content */}
          <div className='pl-8 pr-4 py-4'>
            <div className='border bg-white rounded-md dark:bg-gray-900 p-4'>
              <table className='table-auto w-full text-sm'>
                <thead>
                  <tr className='text-left'>
                    <th className='pl-2 w-1/10'>Actions</th>
                    <th className='pl-2 w-1/6'>Fields</th>
                    <th className='pl-2 w-auto'>Values</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(fields).map(([key, value]) => (
                    <tr
                      key={key}
                      className='text-left hover:bg-primary-verylight dark:hover:bg-gray-700 align-top'
                    >
                      <td className='pl-2 pt-1.5 w-1/10 align-top'>
                        <div className='flex space-x-2'>
                          <button className='focus:outline-none'>
                            <AiOutlineZoomIn />
                          </button>
                          <button className='focus:outline-none'>
                            <AiOutlineZoomOut />
                          </button>
                          <button className='focus:outline-none'>
                            <IoEyeOutline />
                          </button>
                          <button className='focus:outline-none'>
                            <BsGraphUp />
                          </button>
                        </div>
                      </td>
                      <td className='text-gray-800 pl-2 w-1/6 align-top dark:text-gray-300'>
                        {key}
                      </td>
                      <td className='text-gray-600 pl-2 break-all w-auto align-top dark:text-gray-400'>
                        {Array.isArray(value)
                          ? value.map((item, index) => (
                              <div key={index}>{item}</div>
                            ))
                          : value}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogRow;
