import ReconnectingWebSocket from "reconnecting-websocket";
import { v4 as uuidv4 } from "uuid";

// Copy-over from @sailfish/recorder...

// Storing the sailfishSessionId in window.name, as window.name retains its value after a page refresh
// but resets when a new tab (including a duplicated tab) is opened.
function getOrSetSessionId(): string {
  if (!window.name) {
    window.name = uuidv4();
  }
  return window.name;
}

// Frontend-specific
type MessageHandler = (message: MessageEvent) => void;

class WebSocketService {
  private socket: ReconnectingWebSocket | null = null;
  private messageQueue: Array<string> = [];
  private subscribers: Set<MessageHandler> = new Set();
  private heartbeatInterval: NodeJS.Timeout | null = null;

  private getWebSocketUrl(
    backendApi: string,
    apiKey: string,
    endpoint: string,
  ): string {
    const wsHost = this.getWebSocketHost(backendApi);
    const apiProtocol = new URL(backendApi).protocol;
    const wsScheme = apiProtocol === "https:" ? "wss" : "ws";
    const sessionId = getOrSetSessionId();
    return `${wsScheme}://${wsHost}/ws/${endpoint}/?apiKey=${apiKey}&sessionId=${sessionId}`;
  }

  private getWebSocketHost(url: string): string {
    const parsedUrl = new URL(url);
    return `${parsedUrl.hostname}${parsedUrl.port ? `:${parsedUrl.port}` : ""}`;
  }

  connect(endpoint: string, backendApi: string, apiKey: string) {
    if (this.socket) {
      console.warn("WebSocket is already connected.");
      return;
    }

    const wsUrl = this.getWebSocketUrl(backendApi, apiKey, endpoint);
    this.socket = new ReconnectingWebSocket(wsUrl);

    this.socket.addEventListener("open", () => {
      console.log(`Connected to WebSocket: ${wsUrl}`);
      this.flushMessageQueue();
      this.startHeartbeat();
    });

    this.socket.addEventListener("message", (message) => {
      this.notifySubscribers(message);
    });

    this.socket.addEventListener("close", () => {
      console.log("WebSocket disconnected.");
      this.stopHeartbeat();
      this.socket = null;
    });

    this.socket.addEventListener("error", (error) => {
      console.error("WebSocket error:", error);
    });
  }

  sendMessage(message: string) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message);
    } else {
      console.warn("WebSocket not open. Queueing message:", message);
      this.messageQueue.push(message);
    }
  }

  subscribe(handler: MessageHandler) {
    this.subscribers.add(handler);
  }

  unsubscribe(handler: MessageHandler) {
    this.subscribers.delete(handler);
  }

  private notifySubscribers(message: MessageEvent) {
    this.subscribers.forEach((handler) => handler(message));
  }

  disconnect() {
    if (this.socket) {
      this.stopHeartbeat();
      this.socket.close();
      this.socket = null;
    }
  }

  private flushMessageQueue() {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      while (this.messageQueue.length > 0) {
        const message = this.messageQueue.shift();
        if (message) {
          this.socket.send(message);
        }
      }
    }
  }

  private startHeartbeat() {
    if (!this.heartbeatInterval) {
      this.heartbeatInterval = setInterval(() => {
        this.sendMessage(JSON.stringify({ type: "heartbeat" }));
      }, 10000); // Send heartbeat every 10 seconds
    }
  }

  private stopHeartbeat() {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
    }
  }
}

export default new WebSocketService();
