import { useEffect, useState } from "react";
import { IconType } from "react-icons";
import { BsInputCursorText } from "react-icons/bs";
import { HiCursorClick } from "react-icons/hi";
import { IoMdBrowsers } from "react-icons/io";
import {
  GetRecordingSessionUnzippedEventsQuery,
  useGetCaptureSettingsQuery,
  useGetRecordingSessionUnzippedEventsLazyQuery,
} from "../__generated__/graphql";
import { TimelineEvent } from "../utils/interfaces";
import { useCompany } from "../contexts/CompanyContext";

const sanitizeEventAttributes = (
  obj: any,
  cssFromDomain?: string | null,
  cssToDomain?: string | null,
): any => {
  if (Array.isArray(obj)) {
    return obj.map((el) =>
      sanitizeEventAttributes(el, cssFromDomain, cssToDomain),
    );
  } else if (obj && typeof obj === "object") {
    const sanitizedObj = Object.keys(obj).reduce((acc, key) => {
      if (
        key === "href" &&
        obj.rel === "stylesheet" &&
        cssFromDomain &&
        cssToDomain
      ) {
        acc[key] = obj[key].replace(cssFromDomain, cssToDomain);
        return acc;
      }
      const sanitizedKey = key.replace("@", ""); // Remove '@' from key names
      acc[sanitizedKey] = sanitizeEventAttributes(
        obj[key],
        cssFromDomain,
        cssToDomain,
      );
      return acc;
    }, {} as any);

    // Ensure required properties are present and valid
    if (sanitizedObj.type === 4 && !sanitizedObj.data) {
      sanitizedObj.data = {}; // Ensure data property is present for type 4 events
    }

    // Having multiple children in the style tag causes CssSyntaxError in RR Web Player. Join children's text contents into a single child.
    if (
      sanitizedObj.tagName === "style" &&
      Array.isArray(sanitizedObj.childNodes) &&
      sanitizedObj.childNodes.length > 1
    ) {
      sanitizedObj.childNodes = [
        {
          ...sanitizedObj.childNodes[0],
          textContent: sanitizedObj.childNodes
            .map(({ textContent }: any) => textContent)
            .join(""),
        },
      ];
    }

    return sanitizedObj;
  }
  return obj;
};

const useUnzippedEvents = (recordingId?: string | null) => {
  const [unzippedEvents, setUnzippedEvents] = useState<any>(null);
  const [timelineEvents, setTimelineEvents] = useState<TimelineEvent[]>([]);
  const [eventsError, setEventsError] = useState(false);
  const [metadata, setMetadata] = useState<
    | NonNullable<
        GetRecordingSessionUnzippedEventsQuery["recordingSession"]
      >["metadata"]
    | null
  >(null);

  const { selectedCompany } = useCompany();

  const { data: captureSettingsData, loading: captureSettingsLoading } =
    useGetCaptureSettingsQuery({
      variables: {
        companyId: selectedCompany
          ? parseInt(selectedCompany.value, 10)
          : undefined,
      },
    });

  const [fetchUnzippedEvents, { loading: loadingEvents }] =
    useGetRecordingSessionUnzippedEventsLazyQuery({
      onCompleted: (data) => {
        if (data && data.recordingSession) {
          setMetadata(data.recordingSession.metadata);

          const componentIconMap: { [key: string]: IconType } = {
            click: HiCursorClick,
            pageNavigation: IoMdBrowsers,
            textEdit: BsInputCursorText,
          };
          const componentColorMap: { [key: string]: string } = {
            click: "fuchsia-200",
            pageNavigation: "sky-200",
            textEdit: "rose-200",
          };
          const tempTimelineEvents: TimelineEvent[] = [];
          data.recordingSession.userInteractions.clicks.forEach((click) => {
            const type = "click";
            if (click.timestamp === undefined) return;
            tempTimelineEvents.push({
              time: +(click.timestamp || ""),
              text: `${click.type} on ${click.textContent}`,
              color: componentColorMap[type],
              Icon: componentIconMap[type],
              type,
            });
          });
          if (data.recordingSession.pageVisits.length > 0) {
            data.recordingSession.pageVisits.forEach((pageVisit) => {
              const type = "pageNavigation";
              tempTimelineEvents.push({
                time: +pageVisit.activeFrom,
                text: `Navigated to ${pageVisit.url}`,
                color: componentColorMap[type],
                Icon: componentIconMap[type],
                type,
              });
            });
          } else {
            data.recordingSession.userInteractions.pageNavigations.forEach(
              (pageNavigation) => {
                const type = "pageNavigation";
                if (pageNavigation.timestamp === undefined) {
                  return;
                }
                tempTimelineEvents.push({
                  time: +(pageNavigation.timestamp || ""),
                  text: `Navigated to ${pageNavigation.url}`,
                  color: componentColorMap[type],
                  Icon: componentIconMap[type],
                  type,
                });
              },
            );
          }
          data.recordingSession.userInteractions.textEdits.forEach(
            (textEdit) => {
              const type = "textEdit";
              if (textEdit.timestamp === undefined) {
                return;
              }
              const fieldTextIdentifier =
                textEdit.placeholder !== undefined
                  ? `Placeholder: ${textEdit.placeholder}`
                  : textEdit.title !== undefined
                  ? `Title: ${textEdit.title}`
                  : textEdit.details;

              tempTimelineEvents.push({
                time: +(textEdit.timestamp || ""),
                text: `Typed into field with ${fieldTextIdentifier}`,
                color: componentColorMap[type],
                Icon: componentIconMap[type],
                type,
              });
            },
          );
          setTimelineEvents(tempTimelineEvents);

          const sanitizedEvents = sanitizeEventAttributes(
            data.recordingSession.unzippedEvents,
            captureSettingsData?.captureSettings?.cssFromDomain,
            captureSettingsData?.captureSettings?.cssToDomain,
          );

          if (sanitizedEvents.length === 0) {
            console.error(`Error: No events found for the given session.`);
            setEventsError(true);
            return;
          }

          if (sanitizedEvents.length < 2) {
            console.error(
              `Error: Insufficient events found for the given session.`,
            );
            setEventsError(true);
            return;
          }

          setUnzippedEvents(sanitizedEvents);
          setEventsError(false);
        }
      },
      onError: (error) => {
        setEventsError(true);
        console.error("Error fetching unzipped events:", error);
      },
    });

  useEffect(() => {
    const fetchEvents = async () => {
      if (recordingId && !captureSettingsLoading) {
        const fetchVariables = {
          id: recordingId,
          ...(selectedCompany && {
            companyId: parseInt(selectedCompany.value, 10),
          }),
        };
        setEventsError(false);
        fetchUnzippedEvents({ variables: fetchVariables });
      }
    };

    fetchEvents();
  }, [
    recordingId,
    captureSettingsLoading,
    selectedCompany,
    fetchUnzippedEvents,
  ]);

  return {
    unzippedEvents,
    timelineEvents,
    eventsError,
    metadata,
    loadingEvents,
    setUnzippedEvents,
    setTimelineEvents,
    setEventsError,
  };
};

export default useUnzippedEvents;
