import { ReactElement, ReactNode } from "react";
import MUITable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100];

type Row = Record<string, any>;

interface Column<T extends string> {
  id: T;
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: any, row: Row) => ReactNode;
}

interface TableProps<T extends string> {
  columns: Array<Column<T>>;
  count: number;
  idKey: T;
  page: number;
  rows: Array<Row>;
  rowsPerPage: number;
  handleChangeRowsPerPage: (rowsPerPage: number) => void;
  onClick: (id: string) => void;
  setPage: (page: number) => void;
}

const Table = <T extends string>({
  columns,
  count,
  idKey,
  page,
  rows,
  rowsPerPage,
  handleChangeRowsPerPage,
  onClick,
  setPage,
}: TableProps<T>): ReactElement => {
  return (
    <div className='flex flex-col w-full h-full overflow-hidden border rounded-md shadow'>
      <TableContainer className='h-[calc(100%-52px)]'>
        <MUITable stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className='!font-bold'
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow
                  className='cursor-pointer'
                  hover
                  role='checkbox'
                  tabIndex={-1}
                  key={row[idKey]}
                  onClick={() => onClick(row[idKey])}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format ? column.format(value, row) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MUITable>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
        component='div'
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_: any, newPage: number) => setPage(newPage)}
        onRowsPerPageChange={(e) => handleChangeRowsPerPage(+e.target.value)}
      />
    </div>
  );
};

export default Table;
