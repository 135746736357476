// import { useRef, useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import { RecordingSessionType } from "../__generated__/graphql";
import ApplicationPage from "../components/ApplicationPage";
// import { useAdmin } from "../contexts/AdminContext";
// import { useAuth } from "../contexts/AuthContext";
// import { useCompany } from "../contexts/CompanyContext";

// const DEFAULT_RECORDINGS_PER_PAGE = 25;

// // const isDebugMode = false; // import.meta.env.REACT_APP_DEBUG_MODE === "true" || false;

// const fetchLocalEvents = async () => {
//   const response = await fetch(
//     "/rrweb-recordings/replay_2024_04_03T23_17_15.json",
//   );
//   const data = await response.json();
//   return data.data; // Ensure to return the correct structure
// };

// const sanitizeEventAttributes = (obj: any): any => {
//   if (Array.isArray(obj)) {
//     return obj.map(sanitizeEventAttributes);
//   } else if (obj && typeof obj === "object") {
//     const sanitizedObj = Object.keys(obj).reduce((acc, key) => {
//       const sanitizedKey = key.replace("@", ""); // Remove '@' from key names
//       acc[sanitizedKey] = sanitizeEventAttributes(obj[key]);
//       return acc;
//     }, {} as any);

//     // Ensure required properties are present and valid
//     if (sanitizedObj.type === 4 && !sanitizedObj.data) {
//       sanitizedObj.data = {}; // Ensure data property is present for type 4 events
//     }

//     return sanitizedObj;
//   }
//   return obj;
// };

const IndividualIssuePage = () => {
  // const rrwebPlayerRef = useRef<any>(null); // <-- Call useRef here, outside of any condition

  // const { recordingId } = useParams<{ recordingId?: string }>();
  // const { selectedCompany } = useCompany();
  // const [selectedRecording, setSelectedRecording] =
  //   useState<RecordingSessionType | null>(null);
  // const [unzippedEvents, setUnzippedEvents] = useState<any>(null);
  // const [page, setPage] = useState(1);
  // const [recordings, setRecordings] = useState<RecordingSessionType[]>([]);
  // const [totalItemsCount, setTotalItemsCount] = useState(0);
  // const [playerDimensions, setPlayerDimensions] = useState({
  //   width: 640,
  //   height: 480,
  // });
  // const [eventsError, setEventsError] = useState(false);
  // const [recordingsPerPage, setRecordingsPerPage] = useState(
  //   DEFAULT_RECORDINGS_PER_PAGE,
  // );
  // const containerRef = useRef<HTMLDivElement>(null);
  // const navigate = useNavigate();
  // const { user, isSailfishQAMember } = useAuth();
  // const { isAdminEnabled } = useAdmin();

  return <ApplicationPage pageName='*Individual Issue*'>{""} </ApplicationPage>;
};

export default IndividualIssuePage;
