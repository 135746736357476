import { gql, useLazyQuery } from "@apollo/client";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { IoSearch } from "react-icons/io5";
import { useCompany } from "../../contexts/CompanyContext";
import { useAdmin } from "../../contexts/AdminContext";
import { useSearchUsingQueryLazyQuery } from "../../__generated__/graphql";

const DEBUG = false; // Set this to false to disable debug logs

// Define the types for the GraphQL query response
interface AutocompleteField {
  name: string;
  topNValues: string[];
  totalValues: number;
}

interface AutocompleteJsonField {
  name: string;
  fields: AutocompleteField[];
}

interface AutocompleteData {
  textFields: AutocompleteField[];
  numericFields: AutocompleteField[];
  booleanFields: AutocompleteField[];
  timeFields: {
    name: string;
    start: string;
    end: string;
    totalValues: number;
  }[];
  jsonFields: AutocompleteJsonField[];
  allowedComparators: {
    string: string[];
    numeric: string[];
    boolean: string[];
    time: string[];
    json: string[];
  };
}

interface SearchBarProps {
  entity: string;
  itemIds?: string[]; // List of item IDs to restrict the search scope
  onSearchResults: (results: any[]) => void; // Callback to pass search results to the parent component
  limit: number; // For pagination
  offset: number; // For pagination
  setTotalItemsCount: (count: number) => void; // Callback to update total item count
  onLoadingChange: (isLoading: boolean) => void; // Callback to update the loading state in the parent component
  orderBy?: string; // Optional order by argument
  disabled?: boolean; // Optional disable search
}

const FETCH_AUTOCOMPLETE_DATA = gql`
  query getFullAutocompleteData(
    $searchEntity: String!
    $itemIds: [String!]
    $topN: Int!
  ) {
    getFullAutocompleteData(
      searchEntity: $searchEntity
      itemIds: $itemIds
      topN: $topN
    ) {
      textFields {
        name
        topNValues
        totalValues
      }
      numericFields {
        name
        topNValues
        totalValues
      }
      booleanFields {
        name
        topNValues
        totalValues
      }
      timeFields {
        name
        start
        end
        totalValues
      }
      jsonFields {
        name
        fields {
          name
          topNValues
          totalValues
        }
      }
      allowedComparators {
        string
        numeric
        boolean
        time
        json
      }
    }
  }
`;

const SearchBar: React.FC<SearchBarProps> = ({
  entity,
  itemIds,
  onSearchResults,
  limit,
  offset,
  setTotalItemsCount,
  onLoadingChange,
  orderBy, // Add orderBy as a prop
  disabled,
}) => {
  const { selectedCompany } = useCompany();
  const { isAdminEnabled } = useAdmin();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [mode, setMode] = useState<"ScQL" | "JSON">("ScQL");
  const [autoValidate, setAutoValidate] = useState<boolean>(true);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [extraMatches, setExtraMatches] = useState<string[]>([]);
  const [searchBarError, setSearchBarError] = useState<string | null>(null);
  const [activeSuggestion, setActiveSuggestion] = useState<number>(0);
  const [showPlaceholder, setShowPlaceholder] = useState<boolean>(true);
  const [invalidField, setInvalidField] = useState<boolean>(false);
  const [currentEntity, setCurrentEntity] = useState<
    "FieldName" | "Comparator" | "Value" | "Operator"
  >("FieldName");
  const [currentFieldType, setCurrentFieldType] = useState<string | null>(null);
  const [, setSearchResults] = useState<any[]>([]); // Holds the search results
  const [fieldTypeMap, setFieldTypeMap] = useState<Map<string, string>>(
    new Map(),
  );
  const isInitialLoad = useRef(true); // Ref to track the initial load state
  const isAutocompleteDataLogged = useRef(false); // Ref to track if autocomplete data has been logged

  const [searchUsingQuery, { loading: searchLoading }] =
    useSearchUsingQueryLazyQuery();

  const [getFullAutocompleteData, { loading }] = useLazyQuery<{
    getFullAutocompleteData: AutocompleteData;
  }>(FETCH_AUTOCOMPLETE_DATA);

  const [autocompleteData, setAutocompleteData] =
    useState<AutocompleteData | null>(null);

  const executeSearch = useCallback(
    async (initialQuery = searchQuery) => {
      onLoadingChange(true); // Start loading
      try {
        const variables: {
          searchEntity: string;
          searchQuery: string;
          limit: number;
          offset: number;
          orderBy?: string;
          companyId: number;
        } = {
          searchEntity: entity,
          searchQuery: initialQuery,
          limit,
          offset,
          companyId: selectedCompany ? parseInt(selectedCompany.value, 10) : 0,
        };

        if (orderBy) {
          variables.orderBy = orderBy;
        }

        const { data } = await searchUsingQuery({
          variables,
        });

        if (data && isInitialLoad.current) {
          if (DEBUG) {
            console.log("executeSearch", "data", data);
          }
          isInitialLoad.current = false; // Prevent further logging after initial load
        }

        const results = data?.searchUsingQuery?.results.items || [];
        const totalItemsCount =
          data?.searchUsingQuery?.results.totalItemsCount || 0;

        setSearchResults(results);
        onSearchResults(results);
        setTotalItemsCount(totalItemsCount);
      } catch (err) {
        console.error("Search failed", err);
        setSearchBarError("Search failed");
      } finally {
        onLoadingChange(false); // End loading
      }
    },
    [
      entity,
      limit,
      offset,
      onLoadingChange,
      onSearchResults,
      setTotalItemsCount,
      searchUsingQuery,
      orderBy, // Include orderBy in the dependencies
      searchQuery,
      selectedCompany,
    ],
  );

  useEffect(() => {
    if (selectedCompany || !isAdminEnabled) {
      executeSearch(); // Re-execute search when selectedCompany or pagination changes
    }
  }, [isAdminEnabled, selectedCompany, entity, limit, offset, orderBy]);

  useEffect(() => {
    const savedMode = localStorage.getItem("searchMode") as "ScQL" | "JSON";
    const savedAutoValidate = localStorage.getItem("autoValidate") === "true";
    if (savedMode) setMode(savedMode);
    setAutoValidate(savedAutoValidate);
  }, []);

  useEffect(() => {
    localStorage.setItem("searchMode", mode);
    localStorage.setItem("autoValidate", autoValidate.toString());
  }, [mode, autoValidate]);

  // Load autocomplete data and perform the initial search on component mount
  const loadInitialData = useCallback(async () => {
    if (!isInitialLoad.current) return; // Exit if not the initial load

    try {
      const { data: autocompleteData } = await getFullAutocompleteData({
        variables: { searchEntity: entity, itemIds: itemIds || [], topN: 50 },
      });

      setAutocompleteData(autocompleteData?.getFullAutocompleteData || null);

      if (
        autocompleteData?.getFullAutocompleteData &&
        !isAutocompleteDataLogged.current
      ) {
        if (DEBUG) {
          console.log(
            "Loaded autocomplete data",
            autocompleteData.getFullAutocompleteData,
          );
        }
        isAutocompleteDataLogged.current = true; // Set logged flag to prevent further logs
      }

      // Create a map of field names to their types
      const typeMap = new Map<string, string>();

      autocompleteData?.getFullAutocompleteData.textFields.forEach((field) => {
        typeMap.set(field.name.toLowerCase(), "string");
      });

      autocompleteData?.getFullAutocompleteData.numericFields.forEach(
        (field) => {
          typeMap.set(field.name.toLowerCase(), "numeric");
        },
      );

      autocompleteData?.getFullAutocompleteData.booleanFields.forEach(
        (field) => {
          typeMap.set(field.name.toLowerCase(), "boolean");
        },
      );

      autocompleteData?.getFullAutocompleteData.timeFields.forEach((field) => {
        typeMap.set(field.name.toLowerCase(), "time");
      });

      autocompleteData?.getFullAutocompleteData.jsonFields.forEach(
        (jsonField) => {
          jsonField.fields.forEach((field) => {
            typeMap.set(
              `${jsonField.name.toLowerCase()}.${field.name.toLowerCase()}`,
              "json",
            );
          });
        },
      );
      if (DEBUG) {
        console.log("typeMap", typeMap);
      }
      setFieldTypeMap(typeMap); // Store the field type map in state
    } catch (err) {
      console.error(
        "Failed to load autocomplete data or perform initial search",
        err,
      );
    } finally {
      isInitialLoad.current = false; // Set initial load to false after the first load
    }
  }, [entity, itemIds, getFullAutocompleteData]);

  useEffect(() => {
    if (isInitialLoad.current && !autocompleteData) {
      loadInitialData();
    }
  }, [loadInitialData, autocompleteData]);
  // }, [entity, itemIds]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node) &&
        !document
          .querySelector(".extra-match-class")
          ?.contains(event.target as Node)
      ) {
        // Click is outside the search bar and extraMatches, handle like Escape key press
        inputRef.current.blur();
        setSuggestions([]);
        setExtraMatches([]);
        setShowPlaceholder(true);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef, setSuggestions, setExtraMatches, setShowPlaceholder]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let query = e.target.value;
    const selectionStart = e.target.selectionStart || 0;
    const selectionEnd = e.target.selectionEnd || 0;
    const selectedText = query.substring(selectionStart, selectionEnd);

    setShowPlaceholder(false);
    setInvalidField(false);

    // Check if we're entering a value for a string field
    if (currentEntity === "Value" && currentFieldType === "string") {
      // Automatically insert trailing double quote if not present
      if (query.length === 0 || query.slice(-1) !== '"') {
        query = `${query}"`;
        e.target.value = query; // Update the input value directly
        e.target.setSelectionRange(query.length - 1, query.length - 1); // Set cursor before the trailing quote
      }

      // Check for a non-escaped second double quote
      const lastChar = query.charAt(query.length - 1);
      const secondLastChar = query.charAt(query.length - 2);
      const thirdLastChar = query.charAt(query.length - 3);

      if (
        lastChar === '"' &&
        secondLastChar !== "\\" &&
        thirdLastChar === '"' &&
        query.trim().split('"').length % 2 === 0
      ) {
        // If a non-escaped quote is found, add a space and move to the next entity
        query = `${query} `;
        e.target.value = query; // Update the input value directly
        setCurrentEntity("Operator");
        generateSuggestions(query, "Operator");
        return;
      }
    }

    setSearchQuery(query);

    // Reset suggestions and extraMatches whenever input changes
    setSuggestions([]);
    setExtraMatches([]);

    // Determine the context and generate suggestions for the field name
    determineEntityContext(query, selectionStart, selectionEnd, selectedText);
    if (currentEntity === "FieldName") {
      generateSuggestions(query, "FieldName");
    }
  };

  const tokenizeQuery = (query: string): string[] => {
    const tokens: string[] = [];
    let currentToken = "";
    let inQuotes = false;
    let escapeNext = false;

    for (let i = 0; i < query.length; i++) {
      const char = query[i];

      if (escapeNext) {
        currentToken += char;
        escapeNext = false;
      } else if (char === "\\") {
        escapeNext = true;
        currentToken += char;
      } else if (char === '"') {
        inQuotes = !inQuotes;
        currentToken += char;
      } else if (/\s/.test(char) && !inQuotes) {
        if (currentToken.length > 0) {
          tokens.push(currentToken);
          currentToken = "";
        }
      } else {
        currentToken += char;
      }
    }

    if (currentToken.length > 0) {
      tokens.push(currentToken);
    }

    return tokens;
  };

  const determineEntityContext = (
    query: string,
    selectionStart: number,
    selectionEnd: number,
    selectedText: string,
  ) => {
    const tokens = tokenizeQuery(query);
    const tokenCount = tokens.length;

    const N = 4; // We have 4 entities: FieldName, Comparator, Value, Operator
    const remainder = tokenCount % N;

    let currentContextEntity: "FieldName" | "Comparator" | "Value" | "Operator";
    let detectedFieldType: string | null = null; // Correctly determine field type

    // Determine the current entity context based on token count and last character
    if (
      (remainder === 0 && query.slice(-1) === " ") ||
      (remainder === 1 && query.slice(-1) !== " ")
    ) {
      currentContextEntity = "FieldName";
    } else if (
      (remainder === 1 && query.slice(-1) === " ") ||
      (remainder === 2 && query.slice(-1) !== " ")
    ) {
      currentContextEntity = "Comparator";
    } else if (
      (remainder === 2 && query.slice(-1) === " ") ||
      (remainder === 3 && query.slice(-1) !== " ")
    ) {
      currentContextEntity = "Value";
    } else {
      currentContextEntity = "Operator";
    }

    // Check if we are still in "Value" context due to an open string
    const lastToken = tokens[tokens.length - 1];
    if (lastToken && lastToken.startsWith('"') && !lastToken.endsWith('"')) {
      currentContextEntity = "Value";
    }

    // Use the fieldTypeMap to determine the field type
    if (currentContextEntity === "FieldName" && autocompleteData) {
      const lastToken = tokens[tokens.length - 1]?.toLowerCase();
      if (lastToken && fieldTypeMap.has(lastToken)) {
        detectedFieldType = fieldTypeMap.get(lastToken) || null;
      }
    } else if (
      (currentContextEntity === "Comparator" ||
        currentContextEntity === "Value") &&
      autocompleteData
    ) {
      const fieldToken =
        tokens[tokens.length - 3]?.toLowerCase() || tokens[0]?.toLowerCase();
      if (fieldToken && fieldTypeMap.has(fieldToken)) {
        detectedFieldType = fieldTypeMap.get(fieldToken) || null;
      }
    }

    // Update state with the detected context and field type
    setCurrentEntity(currentContextEntity);
    setCurrentFieldType(detectedFieldType);

    // Generate suggestions based on the updated context
    generateSuggestions(query, currentContextEntity);
  };

  // Update generateSuggestions to use dynamic values from autocomplete data
  const generateSuggestions = (
    query: string,
    contextEntity:
      | "FieldName"
      | "Comparator"
      | "Value"
      | "Operator" = currentEntity,
  ) => {
    const tokens = tokenizeQuery(query);
    const isNewToken = query.slice(-1) === " ";
    if (isNewToken) {
      tokens.push("");
    }
    const lastToken = tokens[tokens.length - 1] || ""; // Fallback to empty string

    if (!autocompleteData) return; // Ensure autocomplete data is loaded before generating suggestions

    if (contextEntity === "FieldName") {
      const matchingFields = autocompleteData.textFields
        .concat(autocompleteData.numericFields)
        .concat(autocompleteData.booleanFields)
        .concat(
          autocompleteData.timeFields.map((field) => ({
            name: field.name,
            topNValues: [],
            totalValues: field.totalValues,
          })),
        )
        .concat(
          autocompleteData.jsonFields.flatMap((jsonField) =>
            jsonField.fields.map((field) => ({
              name: `${jsonField.name}.${field.name}`,
              topNValues: field.topNValues,
              totalValues: field.totalValues,
            })),
          ),
        )
        .filter((field) => {
          // Ensure we check both regular fields and jsonFields with correct handling of dots in the name
          const [baseField, subField] = lastToken.toLowerCase().split(".");
          if (subField) {
            const jsonField = autocompleteData.jsonFields.find(
              (jf) => jf.name.toLowerCase() === baseField,
            );
            return (
              jsonField &&
              jsonField.fields.some((f) =>
                f.name.toLowerCase().startsWith(subField),
              )
            );
          } else {
            return field.name.toLowerCase().startsWith(lastToken.toLowerCase());
          }
        });

      setSuggestions(matchingFields.map((field) => field.name));
      setExtraMatches(matchingFields.slice(1).map((value) => value.name));
    } else if (contextEntity === "Comparator" && autocompleteData) {
      if (DEBUG) {
        console.log(`contextEntity === "Comparator && autocompleteData"`);
      }
      const currentFieldToken = tokens[Math.max(0, tokens.length - 2)];
      const currentField = currentFieldToken
        ? currentFieldToken.toLowerCase()
        : "";

      const fieldType = determineFieldType(currentField);
      if (DEBUG) {
        console.log(`fieldType = ${fieldType}`);
      }
      if (fieldType) {
        const comparators =
          autocompleteData.allowedComparators[
            fieldType as keyof typeof autocompleteData.allowedComparators
          ];
        if (lastToken !== "") {
          setSuggestions(
            comparators.filter((comp) => comp.startsWith(lastToken)),
          );
        } else {
          setSuggestions(comparators);
        }
        setExtraMatches(comparators.slice(1));
      }
    } else if (contextEntity === "Value") {
      const currentField = tokens[Math.max(0, tokens.length - 3)].toLowerCase();
      const values = getValuesForField(currentField).map(String); // Convert all values to strings

      // Use fuzzyMatch to order and filter the values
      const matchedValues = fuzzyMatch(lastToken, values);

      const primarySuggestion =
        matchedValues.length > 0 &&
        (matchedValues[0] === '"' ||
          matchedValues[0].startsWith(lastToken) ||
          lastToken === '"')
          ? matchedValues[0]
          : null;

      const extraSuggestions = matchedValues.slice(primarySuggestion ? 1 : 0);

      if (primarySuggestion) {
        setSuggestions([primarySuggestion]);
      } else {
        setSuggestions([]);
      }
      setExtraMatches(extraSuggestions);
    } else if (contextEntity === "Operator") {
      const operators = ["&&", "||", "^^", "!&", "!|", "!!"];
      const matchingOperators = operators.filter((op) =>
        op.startsWith(lastToken),
      );
      setSuggestions(matchingOperators.slice(0, 1));
      setExtraMatches(matchingOperators.slice(1));
    }

    setActiveSuggestion(0);
  };

  const determineFieldType = (fieldName: string): string | null => {
    if (!autocompleteData) return null;

    const fieldTypes = {
      textFields: "string",
      numericFields: "numeric",
      booleanFields: "boolean",
      timeFields: "time",
      jsonFields: "json",
    };

    // First, check if the field is a simple field (non-json) by exact match
    for (const [key, type] of Object.entries(fieldTypes)) {
      if (
        key !== "jsonFields" && // Exclude jsonFields in this loop
        (autocompleteData as any)[key].some(
          (field: AutocompleteField) => field.name.toLowerCase() === fieldName,
        )
      ) {
        return type;
      }
    }

    // Now check if it's a jsonField by splitting the name on the dot
    const [baseField, subField] = fieldName.toLowerCase().split(".");

    if (baseField && subField) {
      const jsonField = autocompleteData.jsonFields.find(
        (jf) => jf.name.toLowerCase() === baseField,
      );

      if (
        jsonField &&
        jsonField.fields.some((f) => f.name.toLowerCase() === subField)
      ) {
        return "json";
      }
    }

    // If no match is found, return null
    return null;
  };

  // Updated getValuesForField to dynamically fetch values from autocompleteData
  const getValuesForField = (fieldName: string): string[] => {
    if (!autocompleteData) return [];

    const jsonFieldParts = fieldName.split(".");
    if (jsonFieldParts.length === 2) {
      const jsonField = autocompleteData.jsonFields.find(
        (jf) => jf.name.toLowerCase() === jsonFieldParts[0].toLowerCase(),
      );
      if (jsonField) {
        const field = jsonField.fields.find(
          (f) => f.name.toLowerCase() === jsonFieldParts[1].toLowerCase(),
        );
        if (field) return field.topNValues;
      }
    }

    const allFields = [
      ...autocompleteData.textFields,
      ...autocompleteData.numericFields,
      ...autocompleteData.booleanFields,
      ...autocompleteData.timeFields.map((field) => ({
        name: field.name,
        topNValues: [],
        totalValues: field.totalValues,
      })),
    ];

    const field = allFields.find(
      (f) => f.name.toLowerCase() === fieldName.toLowerCase(),
    );

    if (field) {
      return field.topNValues;
    }

    return [];
  };

  const fuzzyMatch = (input: string, values: any[]): string[] => {
    // Ignore the initial double quote in the input if present
    const normalizedInput = input.startsWith('"') ? input.slice(1) : input;
    if (DEBUG) {
      console.log(
        "fuzzyMatch",
        "input",
        input,
        "normalizedInput",
        normalizedInput,
        "values",
        values,
      );
    }

    if (normalizedInput === "") return values.map(String); // Convert all values to strings
    if (DEBUG) {
      console.log("normalized input not an empty string...");
    }
    const inputLower = normalizedInput.toLowerCase();

    return values
      .map((value) => {
        const valueStr = String(value); // Convert value to string
        const valueLower = valueStr.toLowerCase();
        let score = 0;
        let inputIndex = 0;

        for (let i = 0; i < valueLower.length; i++) {
          if (
            inputIndex < inputLower.length &&
            valueLower[i] === inputLower[inputIndex]
          ) {
            score++;
            inputIndex++;
          }
        }

        return { value: valueStr, score }; // Return stringified value
      })
      .filter(({ score }) => score > 0)
      .sort((a, b) => b.score - a.score || a.value.length - b.value.length)
      .map(({ value }) => value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const cursorPosition = inputRef.current?.selectionStart || 0;
    const queryLength = searchQuery.length;

    if (e.key === "Escape") {
      e.preventDefault();
      inputRef.current?.blur(); // Unfocus the search bar
      setSuggestions([]);
      setExtraMatches([]);
      return;
    }

    if (e.key === "a" && (e.metaKey || e.ctrlKey)) {
      // CMD + A or CTRL + A
      e.preventDefault();
      inputRef.current?.setSelectionRange(0, queryLength); // Select all text
    }

    if ((e.key === "Backspace" || e.key === "Delete") && queryLength > 0) {
      if (
        inputRef.current?.selectionStart === 0 &&
        inputRef.current?.selectionEnd === queryLength
      ) {
        // If all text is selected, reset the entities
        setSearchQuery("");
        setCurrentEntity("FieldName");
        setCurrentFieldType(null);
        setSuggestions([]);
        setExtraMatches([]);

        generateSuggestions("", "FieldName");
        return;
      }

      handleBackspaceIntoPreviousEntity();
    } else if (
      e.key === "ArrowRight" &&
      cursorPosition === queryLength &&
      (suggestions.length > 0 || extraMatches.length > 0)
    ) {
      e.preventDefault();
      acceptSuggestion();
    } else if (e.key === "ArrowRight" && cursorPosition < queryLength) {
      if (e.metaKey || e.ctrlKey) {
        e.preventDefault();
        inputRef.current?.setSelectionRange(queryLength, queryLength);
      } else {
        inputRef.current?.setSelectionRange(
          cursorPosition + 1,
          cursorPosition + 1,
        );
      }
    } else if (e.key === "ArrowLeft" && cursorPosition > 0) {
      if (e.metaKey || e.ctrlKey) {
        e.preventDefault();
        inputRef.current?.setSelectionRange(0, 0);
      } else {
        inputRef.current?.setSelectionRange(
          cursorPosition - 1,
          cursorPosition - 1,
        );
      }
    } else if (e.key === "Tab" || e.key === "Enter") {
      e.preventDefault();
      acceptSuggestion();
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      setActiveSuggestion(
        (prev) => (prev + 1) % (suggestions.length + extraMatches.length),
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setActiveSuggestion((prev) =>
        prev === 0 ? suggestions.length + extraMatches.length - 1 : prev - 1,
      );
    } else if (currentEntity === "Value" && currentFieldType === "boolean") {
      if (e.key.toLowerCase() === "t") {
        e.preventDefault();
        setSearchQuery(searchQuery + "True ");
        setCurrentEntity("Operator");
        generateSuggestions(searchQuery + "True ", "Operator");
      } else if (e.key.toLowerCase() === "f") {
        e.preventDefault();
        setSearchQuery(searchQuery + "False ");
        setCurrentEntity("Operator");
        generateSuggestions(searchQuery + "False ", "Operator");
      }
    }
  };

  const handleBackspaceIntoPreviousEntity = () => {
    const tokens = tokenizeQuery(searchQuery.trim());

    if (tokens.length === 0) {
      setCurrentEntity("FieldName");
      setCurrentFieldType(null);
      setSuggestions([]);
      setExtraMatches([]);
      return;
    }

    if (currentEntity === "Comparator") {
      const currentField = tokens[Math.max(0, tokens.length - 1)];
      setCurrentEntity("FieldName");
      generateSuggestions(
        searchQuery.slice(0, searchQuery.lastIndexOf(currentField)),
        "FieldName",
      );
    } else if (currentEntity === "Value") {
      const currentField = tokens[Math.max(0, tokens.length - 2)];
      setCurrentEntity("Comparator");
      generateSuggestions(
        searchQuery.slice(0, searchQuery.lastIndexOf(currentField)),
        "Comparator",
      );
    } else if (currentEntity === "Operator") {
      const lastEntityType = tokens[tokens.length - 1];
      const newEntity = determinePreviousEntity(lastEntityType);
      setCurrentEntity(newEntity);
      generateSuggestions(
        searchQuery.slice(0, searchQuery.lastIndexOf(lastEntityType)),
        newEntity,
      );
    }
  };

  const determinePreviousEntity = (
    entityType: string,
  ): "FieldName" | "Comparator" | "Value" | "Operator" => {
    if (entityType === "FieldName") return "FieldName";
    if (entityType === "Comparator") return "Comparator";
    if (entityType === "Value") return "Value";
    return "Operator";
  };

  const acceptSuggestion = (passedSuggestion?: string) => {
    const suggestion = passedSuggestion ?? suggestions[activeSuggestion];

    if (!suggestion) return; // Ensure a valid suggestion exists before proceeding

    const tokens = tokenizeQuery(searchQuery);
    const isNewToken = searchQuery.slice(-1) === " ";
    if (isNewToken) {
      tokens.push("");
    }
    const lastToken = tokens[tokens.length - 1] || ""; // Fallback to empty string

    let updatedQuery = "";
    let currentContextEntity = currentEntity;

    if (currentEntity === "FieldName") {
      updatedQuery =
        searchQuery.slice(0, searchQuery.lastIndexOf(lastToken)) +
        suggestion +
        " ";
      setCurrentEntity("Comparator");
      currentContextEntity = "Comparator";
      setCurrentFieldType(fieldTypeMap.get(suggestion) || null);

      // Log the transition for debugging
      if (DEBUG) {
        console.log("Moving from FieldName to Comparator.");
        console.log("Accepted FieldName:", suggestion);
        console.log(
          `Current FieldType = ${fieldTypeMap.get(suggestion) || null}`,
        );
      }
    } else if (currentEntity === "Comparator") {
      updatedQuery =
        searchQuery.slice(0, searchQuery.lastIndexOf(lastToken)) + suggestion;
      updatedQuery += " ";
      if (currentFieldType === "string") {
        updatedQuery += '"';
      }
      setCurrentEntity("Value");
      currentContextEntity = "Value";

      if (DEBUG) {
        console.log("Moving from Comparator to Value.");
        console.log("Accepted Comparator:", suggestion);
      }
    } else if (currentEntity === "Value") {
      if (currentFieldType === "string") {
        if (!searchQuery.trim().endsWith('"')) {
          updatedQuery =
            searchQuery.slice(0, searchQuery.lastIndexOf(lastToken)) +
            `"${suggestion}" `;
          setCurrentEntity("Operator");
          currentContextEntity = "Operator";
        } else {
          updatedQuery =
            searchQuery.slice(0, searchQuery.lastIndexOf(lastToken)) +
            suggestion +
            '" ';
          setCurrentEntity("Operator");
          currentContextEntity = "Operator";
        }
      } else if (currentFieldType === "boolean") {
        updatedQuery =
          searchQuery.slice(0, searchQuery.lastIndexOf(lastToken)) +
          suggestion +
          " ";
        setCurrentEntity("Operator");
        currentContextEntity = "Operator";
      } else {
        updatedQuery =
          searchQuery.slice(0, searchQuery.lastIndexOf(lastToken)) +
          suggestion +
          " ";
        setCurrentEntity("Operator");
        currentContextEntity = "Operator";
      }

      if (DEBUG) {
        console.log("Moving from Value to Operator.");
        console.log("Accepted Value:", suggestion);
      }
    } else if (currentEntity === "Operator") {
      updatedQuery =
        searchQuery.slice(0, searchQuery.lastIndexOf(lastToken)) + suggestion;
      if (
        suggestion === "&&" ||
        suggestion === "||" ||
        suggestion === "^^" ||
        suggestion === "!&" ||
        suggestion === "!|" ||
        suggestion === "!!"
      ) {
        updatedQuery += " ";
        setCurrentEntity("FieldName");
        currentContextEntity = "FieldName";
      }

      if (DEBUG) {
        console.log("Moving from Operator to FieldName.");
        console.log("Accepted Operator:", suggestion);
      }
    }

    setSearchQuery(updatedQuery);
    generateSuggestions(updatedQuery, currentContextEntity);
  };

  const handleSearch = async () => {
    if (autoValidate) {
      const validationResult = await validateQuery(searchQuery, mode);
      if (!validationResult.isValid) {
        setSearchBarError(validationResult.error);
        return;
      }
    }
    setSearchBarError(null);
    await executeSearch(searchQuery); // Execute the search when the user triggers it
  };

  const handleSpecialCharacters = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const selectedText = window.getSelection()?.toString();
    if (!selectedText) return;

    if (e.key === "(") {
      e.preventDefault();
      setSearchQuery(searchQuery.replace(selectedText, `(${selectedText})`));
    } else if (e.key === "!") {
      e.preventDefault();
      setSearchQuery(searchQuery.replace(selectedText, `!(${selectedText})`));
    }
  };

  const handleFocus = () => {
    setShowPlaceholder(false);
    if (searchQuery === "") {
      setCurrentEntity("FieldName");
      generateSuggestions("", "FieldName");
    } else {
      // Only regenerate suggestions if the search query is not empty
      generateSuggestions(searchQuery, currentEntity);
    }
  };

  const handleBlur = () => {
    if (searchQuery === "") {
      setShowPlaceholder(true);
      setSuggestions([]); // Clear suggestions when the input is empty
      setExtraMatches([]);
    }
  };

  const validateQuery = async (query: string, mode: string) => {
    return { isValid: true, error: "" };
  };

  const highlightFuzzyMatch = (suggestion: string, query: string) => {
    if (typeof suggestion !== "string") return null; // Ensure suggestion is a string
    if (query.length === 0 || query.slice(0, 1) !== '"') return suggestion;
    const queryWithoutFirstDoubleQuote = query.slice(1);
    const queryWithoutFinalNonEscapedDoubleQuote =
      queryWithoutFirstDoubleQuote.slice(-1) === '"' &&
      queryWithoutFirstDoubleQuote.slice(-2) !== '"'
        ? queryWithoutFirstDoubleQuote.slice(0, -1)
        : queryWithoutFirstDoubleQuote;
    if (DEBUG) {
      console.log(
        "highlightFuzzyMatch",
        "queryWithoutFinalNonEscapedDoubleQuote",
        queryWithoutFinalNonEscapedDoubleQuote,
      );
    }
    const queryLower = queryWithoutFinalNonEscapedDoubleQuote.toLowerCase();
    const suggestionLower = suggestion.toLowerCase();

    let highlighted = "";
    let queryIndex = 0;

    for (let i = 0; i < suggestion.length; i++) {
      if (
        queryIndex < queryLower.length &&
        suggestionLower[i] === queryLower[queryIndex]
      ) {
        highlighted += `<span class="text-blue-500">${suggestion[i]}</span>`;
        queryIndex++;
      } else {
        highlighted += suggestion[i];
      }
    }

    const returnVal = queryIndex === queryLower.length ? highlighted : null;
    return returnVal;
  };

  const handleExtraMatchClick = (suggestion: string) => {
    const tokens = tokenizeQuery(searchQuery);
    const isNewToken = searchQuery.slice(-1) === " ";

    if (isNewToken) {
      tokens.push("");
    }

    const lastTokenIndex = tokens.length - 1;
    const lastToken = tokens[lastTokenIndex] || "";

    // Replace the last token with the selected suggestion
    const updatedQuery =
      searchQuery.slice(0, searchQuery.lastIndexOf(lastToken)) +
      (currentEntity === "Value" && currentFieldType === "string"
        ? `"${suggestion}"`
        : suggestion) +
      " ";

    setSearchQuery(updatedQuery);

    if (currentEntity === "Value" && currentFieldType === "string") {
      setCurrentEntity("Operator");
      generateSuggestions(updatedQuery, "Operator");
    } else {
      setCurrentEntity("Operator");
      generateSuggestions(updatedQuery, "Operator");
    }

    inputRef.current?.focus(); // Ensure focus is correctly triggered after accepting a suggestion
  };

  // Inside the SearchBar component
  return (
    <div className='flex flex-col p-4 border border-gray-300 rounded-lg w-full'>
      <div
        className={`flex items-center border p-2 rounded-lg ${
          loading || searchLoading ? "bg-gray-200" : "bg-white"
        }`}
      >
        <IoSearch className='text-gray-500 mr-2' />
        <div
          className='relative w-full'
          style={{ overflow: "visible" }} // Ensure suggestions can overflow
          onMouseDown={(e) => {
            const inputElement = inputRef.current;
            if (document.activeElement !== inputElement) {
              // If the input is not focused, just focus it and do nothing else
              inputElement?.focus();
              return;
            }

            // Only proceed with click logic if input is already focused
            const cursorPosition =
              e.clientX - (inputElement?.getBoundingClientRect().left || 0);
            const suggestionElement =
              document.querySelector(".suggestion-class");
            if (suggestionElement) {
              const suggestionStart =
                suggestionElement.getBoundingClientRect().left -
                (inputElement?.getBoundingClientRect().left || 0);

              // Set a flag if the click is within the suggestion area
              if (cursorPosition >= suggestionStart) {
                e.currentTarget.setAttribute(
                  "data-click-inside-suggestion",
                  "true",
                );
              } else {
                e.currentTarget.removeAttribute("data-click-inside-suggestion");
              }
            }
          }}
          onMouseUp={(e) => {
            const inputElement = inputRef.current;
            if (document.activeElement !== inputElement) {
              // If the input is not focused, do nothing
              return;
            }

            const clickedInsideSuggestion =
              e.currentTarget.getAttribute("data-click-inside-suggestion") ===
              "true";

            if (clickedInsideSuggestion) {
              // Only accept a suggestion if it's specifically within the suggestion area
              acceptSuggestion();
              e.currentTarget.removeAttribute("data-click-inside-suggestion");
            }
          }}
        >
          <input
            ref={inputRef}
            type='text'
            spellCheck={false} // Disable spell check to prevent underlining
            disabled={disabled}
            value={searchQuery}
            onChange={handleInputChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={(e) => {
              handleKeyDown(e);
              handleSpecialCharacters(e);
            }}
            placeholder={showPlaceholder ? `Search ${entity} via ${mode}` : ""}
            className={`w-full border-none outline-none ${
              invalidField ? "underline decoration-red-500" : ""
            }`}
            title={
              invalidField
                ? `"${searchQuery.trim()}" is not a valid field for this search`
                : ""
            }
          />
          {suggestions.length > 0 && (
            <div className='absolute inset-0 flex items-center pointer-events-none'>
              <span className='text-black'>
                {searchQuery.split("").map((char, index) => (
                  <span key={index} className={char === " " ? " " : ""}>
                    {char}
                  </span>
                ))}
                <span className='text-gray-500 suggestion-class'>
                  {(() => {
                    const tokens = tokenizeQuery(searchQuery);
                    let lastToken = tokens[tokens.length - 1] || ""; // Fallback to empty string
                    if (searchQuery.slice(-1) === " ") {
                      lastToken = "";
                    }

                    const suggestion = suggestions[0];
                    if (
                      suggestion &&
                      (lastToken === "" ||
                        suggestion
                          .toLowerCase()
                          .startsWith(lastToken.toLowerCase()))
                    ) {
                      return suggestion.substring(lastToken.length);
                    } else if (suggestion === '"') {
                      return suggestion;
                    }
                    return "";
                  })()}
                </span>
              </span>
            </div>
          )}
          {extraMatches.length > 0 &&
            ReactDOM.createPortal(
              <ul
                className='absolute bg-white border border-gray-300 rounded-md shadow-lg max-h-40 overflow-y-auto z-50'
                style={{
                  top: inputRef.current
                    ? inputRef.current.getBoundingClientRect().bottom +
                      window.scrollY
                    : 0, // Calculate position based on input position
                  left: inputRef.current
                    ? inputRef.current.getBoundingClientRect().left +
                      window.scrollX
                    : 0,
                  width: inputRef.current
                    ? inputRef.current.getBoundingClientRect().width
                    : "auto",
                  position: "fixed", // Fixed positioning to go beyond DashboardTile bounds
                }}
                role='listbox' // Adds semantic meaning for screen readers
                onMouseDown={(e) => {
                  e.stopPropagation(); // Prevent this event from reaching the document or parent elements
                }}
              >
                {extraMatches.map((suggestion, index) => {
                  if (typeof suggestion !== "string") return null; // Skip non-string suggestions
                  const tokens = tokenizeQuery(searchQuery);
                  const isNewToken = searchQuery.slice(-1) === " ";
                  if (isNewToken) {
                    tokens.push("");
                  }
                  const lastToken = tokens[tokens.length - 1] || ""; // Fallback to empty string
                  const isSelected =
                    index + suggestions.length === activeSuggestion;

                  return (
                    <li
                      key={index}
                      className={`p-2 hover:bg-gray-200 cursor-pointer extra-match-class ${
                        index + suggestions.length === activeSuggestion
                          ? "bg-gray-300"
                          : ""
                      }`}
                      role='option' // Adds semantic meaning for screen readers
                      tabIndex={-1} // Prevents default focus but ensures accessibility
                      aria-selected={isSelected} // Indicates if the option is selected
                      onMouseDown={(e) => {
                        e.preventDefault(); // Prevent default behavior to avoid blur or unexpected events
                        e.stopPropagation(); // Stop the click from bubbling up
                      }}
                      onMouseUp={(e) => {
                        e.preventDefault(); // Prevent default behavior to avoid blur or unexpected events
                        e.stopPropagation(); // Stop the click from bubbling up
                        handleExtraMatchClick(suggestion); // Pass the suggestion as an argument
                      }}
                      dangerouslySetInnerHTML={{
                        __html: highlightFuzzyMatch(
                          suggestion,
                          lastToken,
                        ) as string, // Cast to string since nulls are filtered out
                      }}
                    />
                  );
                })}
              </ul>,
              document.body, // Render outside the DashboardTile by attaching to the body
            )}
        </div>
        <button
          onClick={handleSearch}
          className={`px-4 py-2 rounded-lg ml-2 ${
            disabled || loading || searchLoading
              ? "bg-gray-400 text-gray-200 cursor-not-allowed"
              : "bg-blue-500 text-white"
          }`}
          disabled={disabled || loading || searchLoading}
        >
          Search
        </button>
      </div>
      <div className='flex justify-between mt-2'>
        <div className='text-red-500'>
          {searchBarError && <span>{searchBarError}</span>}
        </div>
        <div className='flex items-center space-x-4'>
          <label className='flex items-center'>
            <input
              type='checkbox'
              checked={autoValidate}
              onChange={() => setAutoValidate(!autoValidate)}
              className='mr-2'
            />
            Auto-validate
          </label>
          <label className='flex items-center'>
            <span className='mr-2'>{mode}</span>
            <input
              type='checkbox'
              checked={mode === "JSON"}
              onChange={() => setMode(mode === "ScQL" ? "JSON" : "ScQL")}
              className='mr-2'
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
