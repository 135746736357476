import { List, ListItem, ListItemText } from "@mui/material";
import React, { MutableRefObject, useEffect, useRef } from "react";
import { ExceptionRecordType } from "../../__generated__/graphql";
import Pagination from "../Pagination";

interface ExceptionsListProps {
  containerRef: MutableRefObject<HTMLDivElement | null>;
  exceptions: Array<ExceptionRecordType>;
  loading?: boolean;
  page: number;
  selectedException: string;
  totalPages: number;
  onPageChange: (page: number) => void;
  onSelect: (exceptionId: string) => void;
}

const ExceptionsList: React.FC<ExceptionsListProps> = ({
  containerRef,
  exceptions,
  loading,
  page,
  selectedException,
  totalPages,
  onPageChange,
  onSelect,
}) => {
  const selectedExceptionRef = useRef<HTMLDivElement | null>(null); // Ref for the selected exception

  useEffect(() => {
    // Scroll only the container (not the full page) to bring the selected exception into view
    if (selectedExceptionRef.current && containerRef.current) {
      containerRef.current.scrollTo({
        top:
          selectedExceptionRef.current.offsetTop -
          containerRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  }, []); // Empty dependency array ensures it runs after initial render

  return (
    <div className='flex flex-col h-full'>
      <div
        ref={containerRef} // Ref for the container that will scroll
        className='flex-1 overflow-auto'
      >
        {loading ? (
          <div>Loading exceptions...</div>
        ) : (
          <List>
            {exceptions.map(({ id, timestampMs }) => (
              <ListItem
                ref={selectedException === id ? selectedExceptionRef : null}
                button
                key={id}
                onClick={() => onSelect(id)}
                className={selectedException === id ? "!bg-blue-100" : ""}
              >
                <ListItemText
                  primary={`Exception ${id}`}
                  primaryTypographyProps={{ fontSize: 14 }}
                  secondary={`${new Date(
                    parseInt(timestampMs),
                  ).toLocaleString()}`}
                  secondaryTypographyProps={{ fontSize: 12 }}
                />
              </ListItem>
            ))}
          </List>
        )}
      </div>
      <div className='bg-white'>
        <Pagination
          page={page}
          totalPages={totalPages}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  );
};

export default ExceptionsList;
