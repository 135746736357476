import { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ApplicationPage from "../components/ApplicationPage";
import RecordingsList from "../components/RecordingsListWithEvents";
import RRWebPlayerWithTriage from "../components/RRWebPlayerWithTriage";
import { RecordingSessionType } from "../__generated__/graphql";
import SearchComboboxes from "../components/Search/SearchComboboxes";
import useUnzippedEvents from "../hooks/useUnzippedEvents";
import useContainerWidth from "../hooks/useContainerWidth";

const RECORDINGS_PER_PAGE = 10;
const PLAYER_HEIGHT_PX = 462;

const ReplaysWithSearchAndExaminePage = () => {
  const rrwebPlayerRef = useRef<any>(null);

  const { recordingId } = useParams<{ recordingId?: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedRecording, setSelectedRecording] =
    useState<RecordingSessionType | null>(null);
  const pageInQuery = Number(searchParams.get("page"));
  const [page, setPage] = useState(pageInQuery || 1);
  const [recordings, setRecordings] = useState<RecordingSessionType[]>([]);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // Select recording after fetching the list. -1 - select last item, 1 select first item, 0 don't select
  const [selectRecordingAfterFetch, setSelectRecordingAfterFetch] = useState(0);

  const {
    unzippedEvents,
    timelineEvents,
    eventsError,
    metadata,
    loadingEvents,
    setUnzippedEvents,
    setTimelineEvents,
  } = useUnzippedEvents(selectedRecording?.id || recordingId);
  const { containerRef, containerWidth } = useContainerWidth();

  const handleSelectRecording = (recording: RecordingSessionType) => {
    if (selectedRecording?.id === recording.id) {
      // Same recording selected, seek back to the start and keep playing
      rrwebPlayerRef.current?.resetPlayer();
    } else {
      // Different recording selected, pause the player and load new recording
      setUnzippedEvents(null); // Reset events to force RRWebPlayer re-initialization
      setTimelineEvents([]);
      setSelectedRecording(recording);
      navigate(
        `/recordings/${recording.id}${
          pageInQuery ? `?page=${pageInQuery}` : ""
        }`,
      );
    }
    rrwebPlayerRef.current?.pause();
  };

  const handleSearchResults = (results: any[]) => {
    setRecordings(results);
    if (results.length && selectRecordingAfterFetch) {
      setSelectRecordingAfterFetch(0);

      // Parse `durationMs` only temporarily for selection
      const selectedRecording =
        results[selectRecordingAfterFetch > 0 ? 0 : results.length - 1];
      handleSelectRecording({
        ...selectedRecording,
        durationMs: selectedRecording.durationMs
          ? selectedRecording.durationMs
          : null,
      });
    }
  };

  const handleTotalItemsCount = (count: number) => {
    setTotalItemsCount(count); // Update the total items count
  };

  const totalPages = Math.ceil(totalItemsCount / RECORDINGS_PER_PAGE);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    setSearchParams((params) => {
      params.set("page", `${newPage}`);
      return params;
    });
  };

  useEffect(() => {
    // Calculate the new total pages based on the updated totalItemsCount
    const newTotalPages = Math.ceil(totalItemsCount / RECORDINGS_PER_PAGE);

    // If the current page exceeds the new total pages, set the page to the new max page
    if (page > newTotalPages && newTotalPages > 0) {
      setPage(newTotalPages);
      setSearchParams((params) => {
        params.set("page", `${newTotalPages}`);
        return params;
      });
    }
  }, [page, setSearchParams, totalItemsCount]);

  const currentRecordingIndex = recordings.findIndex(
    ({ id }) => id === recordingId || id === selectedRecording?.id,
  );
  const isFirstRecording = currentRecordingIndex === 0;
  const isLastRecording = currentRecordingIndex === recordings.length - 1;

  const handleBackwardRecording = () => {
    if (isFirstRecording) {
      setSelectRecordingAfterFetch(-1);
      handlePageChange(page - 1);
    } else {
      handleSelectRecording(recordings[currentRecordingIndex - 1]);
    }
  };

  const handleForwardRecording = () => {
    if (isLastRecording) {
      setSelectRecordingAfterFetch(1);
      handlePageChange(page + 1);
    } else {
      handleSelectRecording(recordings[currentRecordingIndex + 1]);
    }
  };

  const backwardRecordingDisabled = isFirstRecording && page === 1;
  const forwardRecordingDisabled = isLastRecording && page === totalPages;

  return (
    <ApplicationPage
      pageName='Recordings'
      helpLink='https://docs.sailfishqa.com/'
      helpTooltip='Click here to learn about Recordings.'
    >
      {/* <Helmet>
        <title>Sailfish - Recordings</title>
        <meta name='description' content='Nested component' />
      </Helmet> */}
      <div className='flex gap-4 max-h-[calc(100%-56px)]'>
        <div className='flex-[5] min-w-0'>
          <SearchComboboxes
            entity='Recordings'
            limit={RECORDINGS_PER_PAGE}
            offset={(page - 1) * RECORDINGS_PER_PAGE}
            onSearchResults={handleSearchResults}
            setTotalItemsCount={handleTotalItemsCount}
            onLoadingChange={setLoading}
            orderBy='-start_timestamp'
          />
          <div ref={containerRef}>
            <div className='h-full'>
              <RRWebPlayerWithTriage
                key={recordingId || "none"} // Re-mount the component on video change so all calculations are re-done
                id={recordingId}
                ref={rrwebPlayerRef}
                events={unzippedEvents}
                timelineEvents={timelineEvents}
                highlightedSections={[]}
                width={containerWidth - 16}
                height={PLAYER_HEIGHT_PX}
                containerWidth={containerWidth}
                eventsError={eventsError}
                loadingEvents={loadingEvents}
                handleBackwardRecording={handleBackwardRecording}
                handleForwardRecording={handleForwardRecording}
                backwardRecordingDisabled={backwardRecordingDisabled}
                forwardRecordingDisabled={forwardRecordingDisabled}
              />
            </div>
          </div>
        </div>
        <div className='flex flex-[2] flex-col gap-2 max-h-full min-w-0'>
          <Accordion
            className='!shadow-md border border-gray-200 !rounded !m-0 flex flex-col p-2.5 overflow-auto'
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1-content'
              id='panel1-header'
              className='text-sm font-semibold !min-h-8'
              classes={{ content: "!my-0" }}
            >
              All Recordings ({totalItemsCount || 0})
            </AccordionSummary>
            <RecordingsList
              recordings={recordings}
              onSelect={handleSelectRecording}
              page={page}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              loading={loading}
              loadingEvents={loadingEvents}
              recordingId={recordingId}
              timelineEvents={timelineEvents}
              onClickEvent={(time) => {
                // Seek to the time in the player without changing its play state
                if (rrwebPlayerRef.current) {
                  rrwebPlayerRef.current.seekToTime(time);
                }
              }}
            />
          </Accordion>
          <Accordion
            className='!shadow-md border border-gray-200 !rounded !m-0 flex flex-col p-2.5 overflow-auto min-h-14'
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel2-content'
              id='panel2-header'
              className='text-sm font-semibold !min-h-8'
              classes={{ content: "!my-0" }}
            >
              User Data
            </AccordionSummary>
            {metadata ? (
              <div className='text-xs text-center flex flex-col gap-4'>
                <div className='flex flex-col gap-1'>
                  <div className='text-sm font-medium'>User</div>
                  <div>{metadata.user}</div>
                </div>
                <div className='flex flex-col gap-1'>
                  <div className='text-sm font-medium'>Device</div>
                  <div>
                    {metadata.device.os?.name}, {metadata.device.os?.version}
                    <br />
                    {`${metadata.device.client?.name}, ${metadata.device.client?.version}`}
                  </div>
                </div>
                <div className='flex flex-col gap-1'>
                  <div className='text-sm font-medium'>Time Zone</div>
                  <div>
                    {metadata.timeZone !== null ? metadata.timeZone : "UNKNOWN"}
                  </div>
                </div>
                <div className='flex flex-col gap-1'>
                  <div className='text-sm font-medium'>Language</div>
                  <div>{metadata.language}</div>
                </div>
              </div>
            ) : unzippedEvents ? (
              <div></div>
            ) : (
              <div className='text-center text-sm leading-6 text-zinc-700 flex flex-col min-h-16 justify-center px-8'>
                😬 nothing to show, play a video to get started
              </div>
            )}
          </Accordion>
        </div>
      </div>
    </ApplicationPage>
  );
};

export default ReplaysWithSearchAndExaminePage;
