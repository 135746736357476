import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";
import { FaRegThumbsUp, FaThumbsUp } from "react-icons/fa6";
import ApplicationPage from "../components/ApplicationPage";
import LoadingAnimation from "../components/LoadingAnimation";
import RemainingAreaContainer from "../components/RemainingAreaContainer";

const GET_FEATURE_REQUESTS = gql`
  query GetFeatureRequests {
    featureRequests {
      id
      title
      description
      totalVotes
      companiesVoted
      dateRequested
      lastUpdate
      votedByUser
    }
  }
`;

const SUBMIT_FEATURE_REQUEST = gql`
  mutation SubmitFeatureRequest($title: String!, $description: String!) {
    submitFeatureRequest(title: $title, description: $description) {
      id
      title
      description
      totalVotes
      companiesVoted
      dateRequested
      lastUpdate
    }
  }
`;

const TOGGLE_VOTE = gql`
  mutation ToggleVote($requestId: ID!) {
    toggleVote(requestId: $requestId) {
      id
      totalVotes
      votedByUser
    }
  }
`;

// const WideTableCell = styled(TableCell)({
//   flex: "1 1 auto", // Flex-grow to take as much space as possible
//   whiteSpace: "nowrap", // Ensure text doesn't wrap
//   overflow: "hidden", // Hide overflow
//   textOverflow: "ellipsis", // Add ellipsis for overflow text
// });

// const NarrowTableCell = styled(TableCell)({
//   flex: "0 0 auto", // Flex-grow to 0 to take only the width of the content
//   whiteSpace: "nowrap", // Ensure text doesn't wrap
//   overflow: "hidden", // Hide overflow
//   textOverflow: "ellipsis", // Add ellipsis for overflow text
// });

const StyledTableContainer = styled("div")({
  width: "100%",
  overflowX: "auto",
});

const StyledTable = styled(Table)({
  minWidth: "100%",
  tableLayout: "fixed",
});

const StyledTableCell = styled(TableCell)({
  "&.MuiTableCell-head": {
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
});

const FeatureRequests: React.FC = () => {
  const { data, loading, error } = useQuery(GET_FEATURE_REQUESTS);
  const [toggleVote] = useMutation(TOGGLE_VOTE);
  const [submitFeatureRequest] = useMutation(SUBMIT_FEATURE_REQUEST, {
    refetchQueries: [{ query: GET_FEATURE_REQUESTS }],
  });

  const [openModal, setOpenModal] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const [newDescription, setNewDescription] = useState("");

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleSubmit = async () => {
    await submitFeatureRequest({
      variables: { title: newTitle, description: newDescription },
    });
    handleCloseModal();
  };

  const handleVote = async (
    requestId: string,
    currentVotes: number,
    currentUserVoted: boolean,
  ) => {
    const newVotes = currentUserVoted ? currentVotes - 1 : currentVotes + 1;
    await toggleVote({
      variables: { requestId },
      optimisticResponse: {
        toggleVote: {
          id: requestId,
          votes: newVotes,
          votedByUser: !currentUserVoted,
          __typename: "FeatureRequest",
        },
      },
      update: (cache, { data: { toggleVote } }) => {
        const existingFeatureRequests: any = cache.readQuery({
          query: GET_FEATURE_REQUESTS,
        });
        const updatedFeatureRequests =
          existingFeatureRequests.featureRequests.map((request: any) =>
            request.id === requestId
              ? {
                  ...request,
                  votes: toggleVote.votes,
                  votedByUser: toggleVote.votedByUser,
                }
              : request,
          );
        cache.writeQuery({
          query: GET_FEATURE_REQUESTS,
          data: { featureRequests: updatedFeatureRequests },
        });
      },
    });
  };

  if (loading) return <LoadingAnimation text='Loading...' />;
  if (error) return <p>Error loading feature requests</p>;

  const featureRequests = data.featureRequests;

  return (
    <ApplicationPage pageName='Feature Requests'>
      <Button variant='contained' color='primary' onClick={handleOpenModal}>
        Submit a New Feature Request
      </Button>
      <RemainingAreaContainer
        idsX={["appSidebar"]}
        idsY={["applicationPageHeader"]}
        initialClassName='mt-4'
      >
        <StyledTableContainer>
          <StyledTable>
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ width: "20%" }}>
                  Title
                </StyledTableCell>
                <StyledTableCell style={{ width: "40%" }}>
                  Description
                </StyledTableCell>
                {/* <StyledTableCell style={{ width: "8%" }}>
                  # of Votes
                </StyledTableCell>
                <StyledTableCell style={{ width: "8%" }}>
                  Companies Voted
                </StyledTableCell> */}
                <StyledTableCell style={{ width: "8%" }}>
                  Request Date
                </StyledTableCell>
                <StyledTableCell style={{ width: "8%" }}>
                  Last Update
                </StyledTableCell>
                <StyledTableCell style={{ width: "8%" }}>Vote</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {featureRequests.map((request: any) => (
                <TableRow key={request.id}>
                  <StyledTableCell style={{ width: "20%" }}>
                    {request.title}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: "40%" }}>
                    {request.description}
                  </StyledTableCell>
                  {/* <StyledTableCell style={{ width: "8%" }}>
                    {request.totalVotes}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: "8%" }}>
                    {request.companiesVoted.join(", ")}
                  </StyledTableCell> */}
                  <StyledTableCell style={{ width: "8%" }}>
                    {new Date(request.dateRequested).toLocaleDateString()}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: "8%" }}>
                    {new Date(request.lastUpdate).toLocaleDateString()}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: "8%" }}>
                    <IconButton
                      onClick={() =>
                        handleVote(
                          request.id,
                          request.totalVotes,
                          request.votedByUser,
                        )
                      }
                    >
                      {request.votedByUser ? (
                        <FaThumbsUp color='green' />
                      ) : (
                        <FaRegThumbsUp />
                      )}
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
      </RemainingAreaContainer>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            p: 4,
            backgroundColor: "white",
            margin: "auto",
            borderRadius: "8px",
          }}
        >
          <h2>Submit a New Feature Request</h2>
          <TextField
            label='Title'
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
            fullWidth
          />
          <TextField
            label='Description'
            value={newDescription}
            onChange={(e) => setNewDescription(e.target.value)}
            fullWidth
            multiline
            rows={4}
            margin='normal'
          />
          <Button variant='contained' color='primary' onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </Modal>
    </ApplicationPage>
  );
};

export default FeatureRequests;
