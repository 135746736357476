import { gql, useLazyQuery } from "@apollo/client";
import {
  Autocomplete,
  CircularProgress,
  Popper,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { useSearchUsingQueryLazyQuery } from "../../__generated__/graphql";
import { useCompany } from "../../contexts/CompanyContext";

dayjs.extend(utc);

// Custom PopperComponent to ensure consistent dropdown positioning
const CustomPopper = (props: any) => {
  const { anchorEl, ...other } = props;

  return (
    <Popper
      {...other}
      anchorEl={anchorEl}
      style={{
        width: "auto", // Make width dynamic to fit content
        minWidth: anchorEl?.clientWidth || 0, // Set minimum width to anchor element width (input field)
        whiteSpace: "nowrap", // Prevent content from wrapping
      }}
      placement='bottom-start'
    />
  );
};

const GET_FIELD_VALUES = gql`
  query GetFieldValues(
    $fieldName: String!
    $searchEntity: String!
    $companyId: Int
  ) {
    getFieldValues(
      fieldName: $fieldName
      searchEntity: $searchEntity
      companyId: $companyId
    )
  }
`;

interface SearchComboboxesProps {
  entity: string;
  limit: number;
  offset: number;
  onSearchResults: (results: any[]) => void;
  setTotalItemsCount: (count: number) => void;
  onLoadingChange: (isLoading: boolean) => void;
  orderBy?: string;
}

const SearchComboboxes: React.FC<SearchComboboxesProps> = ({
  entity,
  limit,
  offset,
  onSearchResults,
  setTotalItemsCount,
  onLoadingChange,
  orderBy,
}) => {
  const { selectedCompany } = useCompany();
  const [userIds, setUserIds] = useState<string[]>([]);
  const [pageVisitUrls, setPageVisitUrls] = useState<string[]>([]); // New state for page visit URLs
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [selectedPageVisitUrl, setSelectedPageVisitUrl] = useState<
    string | null
  >(null); // New state for selected URL
  const [selectedTimestamp, setSelectedTimestamp] = useState<Dayjs | null>(
    null,
  );
  const [inputUserIdValue, setInputUserIdValue] = useState<string>(""); // Initialized with an empty string

  const [fetchUserIds, { data: userIdsData, loading: userIdsLoading }] =
    useLazyQuery(GET_FIELD_VALUES);

  const [
    fetchPageVisitUrls,
    { data: pageVisitUrlsData, loading: pageVisitUrlsLoading },
  ] = useLazyQuery(GET_FIELD_VALUES);

  const [searchUsingQuery] = useSearchUsingQueryLazyQuery();

  useEffect(() => {
    const variables = {
      searchEntity: entity,
      ...(selectedCompany && {
        companyId: parseInt(selectedCompany.value, 10),
      }),
    };

    // Fetch user IDs
    fetchUserIds({
      variables: {
        fieldName: "user_id",
        ...variables,
      },
    });

    // Fetch page visit URLs
    fetchPageVisitUrls({
      variables: {
        fieldName: "page_visits",
        ...variables,
      },
    });

    setSelectedUserId(null);
    setSelectedPageVisitUrl(null);
  }, [selectedCompany, fetchUserIds, fetchPageVisitUrls, entity]);

  useEffect(() => {
    if (userIdsData) {
      setUserIds(userIdsData.getFieldValues);
    }
  }, [userIdsData]);

  useEffect(() => {
    if (pageVisitUrlsData) {
      setPageVisitUrls(pageVisitUrlsData.getFieldValues);
    }
  }, [pageVisitUrlsData]);

  useEffect(() => {
    const fetchSearchResults = async () => {
      let searchQuery = "";
      if (selectedUserId) {
        searchQuery += `user_id == "${selectedUserId}"`;
      }
      if (selectedPageVisitUrl) {
        const pageVisitQuery = `page_visits__page_url__url == "${selectedPageVisitUrl}"`;
        if (searchQuery) {
          searchQuery += ` && ${pageVisitQuery}`;
        } else {
          searchQuery = pageVisitQuery;
        }
      }
      if (selectedTimestamp) {
        const timestamp = selectedTimestamp.valueOf(); // Milliseconds since epoch
        const endTimestampCheckForSessionsThatAreStillActive =
          "end_timestamp == 0"; // Though the field is null, this check works
        const timestampQuery = `start_timestamp <= ${
          timestamp + 5 * 60 * 1000 // Add 5 min buffer
        } && (end_timestamp >= ${timestamp} || ${endTimestampCheckForSessionsThatAreStillActive})`;
        if (searchQuery) {
          searchQuery += ` && ${timestampQuery}`;
        } else {
          searchQuery = timestampQuery;
        }
      }

      const variables = {
        searchEntity: entity,
        searchQuery,
        limit,
        offset,
        companyId: selectedCompany ? parseInt(selectedCompany.value, 10) : 0,
        orderBy,
      };

      onLoadingChange(true); // Start loading state
      try {
        const { data } = await searchUsingQuery({ variables });

        if (data) {
          const results = data.searchUsingQuery?.results.items || [];
          onSearchResults(results);
          setTotalItemsCount(
            data.searchUsingQuery?.results.totalItemsCount || 0,
          );
        }
      } catch (err) {
        console.error("Search failed", err);
      } finally {
        onLoadingChange(false); // End loading state
      }
    };

    fetchSearchResults(); // Fetch on variables change
  }, [
    selectedUserId,
    selectedTimestamp,
    selectedPageVisitUrl,
    entity,
    limit,
    offset,
    selectedCompany,
    orderBy,
  ]);

  return (
    <div className='flex flex-col px-1 pb-4 w-full'>
      <div className='flex items-center space-x-4'>
        <Autocomplete
          id='user-id-autocomplete'
          className='flex-1'
          options={userIds}
          loading={userIdsLoading}
          value={selectedUserId} // This binds the selectedUserId to the Autocomplete's value
          inputValue={inputUserIdValue} // This ensures the entered value is shown in the input field
          onInputChange={(event, newInputValue) => {
            setInputUserIdValue(newInputValue); // Update the input value as the user types
          }}
          onChange={(event, newValue) => {
            setSelectedUserId(newValue ? newValue : null); // Set the selected user ID
            setInputUserIdValue(newValue ? newValue : ""); // Ensure the input shows the selected value
          }}
          PopperComponent={CustomPopper} // Ensure the dropdown is always below
          renderInput={(params) => (
            <TextField
              {...params}
              label='User ID'
              variant='outlined'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {userIdsLoading ? (
                      <CircularProgress color='inherit' size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  const value = params.inputProps.value
                    ? String(params.inputProps.value)
                    : null;

                  // Set the selected user ID and input value
                  setSelectedUserId(value);
                  setInputUserIdValue(value || "");

                  // Close the dropdown by clearing the input focus
                  e.preventDefault();
                  (e.target as HTMLInputElement).blur();
                }
              }}
            />
          )}
          ListboxComponent={
            VirtualizedListboxComponent as React.ComponentType<
              React.HTMLAttributes<HTMLElement>
            >
          }
          ListboxProps={{
            style: {
              whiteSpace: "nowrap",
            },
          }}
        />
        <Autocomplete
          id='page-visit-url-autocomplete'
          className='flex-1'
          options={pageVisitUrls} // New dropdown for page visit URLs
          loading={pageVisitUrlsLoading}
          onChange={(event, newValue) => {
            setSelectedPageVisitUrl(newValue ? newValue : null);
          }}
          PopperComponent={CustomPopper} // Ensure the dropdown is always below
          renderInput={(params) => (
            <TextField
              {...params}
              label='Page Visit URL'
              variant='outlined'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {pageVisitUrlsLoading ? (
                      <CircularProgress color='inherit' size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          ListboxComponent={
            VirtualizedListboxComponent as React.ComponentType<
              React.HTMLAttributes<HTMLElement>
            >
          }
          ListboxProps={{
            style: {
              whiteSpace: "nowrap",
            },
          }}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            timezone='UTC'
            label='Date & Time (UTC)'
            className='flex-1'
            value={selectedTimestamp}
            onChange={(newValue: Dayjs | null) => {
              setSelectedTimestamp(newValue);
            }}
            slotProps={{
              textField: {
                variant: "outlined",
              } as Partial<TextFieldProps>,
              actionBar: {
                actions: ["clear"],
              },
            }}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default SearchComboboxes;

// Virtualized ListboxComponent for Autocomplete
function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
}

const LISTBOX_PADDING = 8; // Adjust for padding

const VirtualizedListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function VirtualizedListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 36;

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemCount * itemSize;
  };

  return (
    // WE NEED THIS TO RENDER
    <div ref={ref} {...other}>
      <FixedSizeList
        height={getHeight() + LISTBOX_PADDING}
        width='100%'
        itemSize={itemSize}
        itemCount={itemCount}
        overscanCount={5}
        itemData={itemData}
      >
        {renderRow}
      </FixedSizeList>
    </div>
  );
});
