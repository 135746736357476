import { useEffect, useState } from "react";
import {
  LuBell,
  LuLayoutGrid,
  LuSettings2,
  LuSunset,
  LuVideo,
} from "react-icons/lu";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import ApplicationPage from "../components/ApplicationPage";
import Modal from "../components/Modal";
import Configuration from "../components/Settings/Configuration";
import Integrations from "../components/Settings/Integrations";
import IntegrationsLinear from "../components/Settings/IntegrationsLinear";
import IntegrationsZendesk from "../components/Settings/IntegrationsZendesk";
import NotificationSettings from "../components/Settings/Notifications";
import Recording from "../components/Settings/Recording";
import SetupInstall from "../components/Settings/SetupInstall";
import VerticalMenu from "../components/VerticalMenu";
import { useUnsavedChanges } from "../contexts/UnsavedChangesContext";
import { SettingsTabNames } from "../utils/interfaces";

const MENU_ITEMS = [
  {
    id: "setup",
    title: "Install / Setup",
    icon: <LuSunset size={16} />,
    Component: SetupInstall,
    children: [
      { id: "static", title: "Static Frontend / Unmanaged Sites" },
      { id: "fe", title: "Frontend" },
      { id: "be", title: "Backend & Server-Side Scripts" },
    ],
  },
  {
    id: "notifications",
    title: "Notifications",
    icon: <LuBell size={16} />,
    Component: NotificationSettings,
    children: [
      { id: "email", title: "Email Notification Settings" },
      { id: "webhook", title: "Webhook Notification Settings" },
    ],
  },
  {
    id: "recording",
    title: "Recording",
    icon: <LuVideo size={16} />,
    Component: Recording,
    children: [
      { id: "general", title: "General" },
      { id: "privacy", title: "Privacy" },
    ],
  },
  {
    id: "configuration",
    title: "Configuration",
    icon: <LuSettings2 size={16} />,
    Component: Configuration,
    children: [
      { id: "sailfish", title: "Sailfish Configuration" },
      { id: "site", title: "Site Configuration" },
    ],
  },
  {
    id: "integrations",
    title: "Integrations",
    icon: <LuLayoutGrid size={16} />,
    Component: Integrations,
    children: [{ id: "linear", title: "Linear" }],
  },
];

const Settings = () => {
  const [activeTab, setActiveTab] = useState<SettingsTabNames>("setup");
  const { isDirty, externalSave } = useUnsavedChanges();

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [nextLocation, setNextLocation] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const parentId = location.pathname.split("/").pop() || "";
  const childId = location.hash.substring(1); // Remove # from the start

  const navigateToPath = (parentId: string, childId: string) =>
    `/settings/${parentId}#${childId}`;

  useEffect(() => {
    const currentSection = parentId || "setup";
    setActiveTab(currentSection as SettingsTabNames);
  }, [parentId]);

  useEffect(() => {
    const menu = MENU_ITEMS.find(({ id }) => id === parentId);
    if (menu && (!childId || !menu.children.some(({ id }) => id === childId))) {
      navigate(navigateToPath(parentId, menu.children[0].id));
    }
  }, [parentId, childId]);

  const handleSectionChange = (parentId: string, childId: string) => {
    const nextPath = navigateToPath(parentId, childId);
    if (isDirty(activeTab) && location.pathname !== nextPath) {
      setShowConfirmModal(true);
      setNextLocation(nextPath);
    } else {
      navigate(nextPath);
    }
  };

  const handleUserDecision = async (
    saveChanges: boolean,
    navigateAnyway: boolean,
  ) => {
    if (saveChanges) {
      await externalSave(activeTab);
    }
    if (navigateAnyway) {
      navigate(nextLocation);
    }
    setShowConfirmModal(false);
  };

  const defaultMenu = MENU_ITEMS[0];
  const defaultPath = `${defaultMenu.id}#${defaultMenu.children[0].id}`;

  return (
    <ApplicationPage pageName='Settings'>
      <div className='flex gap-4 h-[calc(100%-56px)]'>
        <div className='flex h-full'>
          <VerticalMenu
            menuItems={MENU_ITEMS}
            selectedMenu={`${parentId}-${childId}`}
            onClick={handleSectionChange}
          />
        </div>
        <div className='flex-1 h-full'>
          <Routes>
            <Route path='/' element={<Navigate replace to={defaultPath} />} />
            {MENU_ITEMS.map(({ id, Component }) => (
              <Route
                key={id}
                path={id}
                element={<Component section={childId} />}
              />
            ))}
            <Route
              path='integrations/linear'
              element={<IntegrationsLinear />}
            />
            <Route
              path='integrations/zendesk'
              element={<IntegrationsZendesk />}
            />
            <Route path='*' element={<Navigate replace to={defaultPath} />} />
          </Routes>
          {showConfirmModal && (
            <Modal
              onClose={() => handleUserDecision(false, false)}
              onConfirm={() => handleUserDecision(true, true)}
              onDiscard={() => handleUserDecision(false, true)}
              changes={[]}
            />
          )}
        </div>
      </div>
    </ApplicationPage>
  );
};

export default Settings;
