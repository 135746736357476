import { useEffect } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useCreateEngineeringTicketPlatformIntegrationMutation } from "../../__generated__/graphql";
import { useCompany } from "../../contexts/CompanyContext";
import { INTEGRATIONS } from "../../utils/constants";
import LoadingAnimation from "../LoadingAnimation";

const { ZENDESK, ZENDESK_REDIRECT_URI, ZENDESK_INTEGRATIONS_PATH } =
  INTEGRATIONS;

const IntegrationsZendesk = () => {
  const { selectedCompany } = useCompany();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  const [createIntegration] =
    useCreateEngineeringTicketPlatformIntegrationMutation();

  useEffect(() => {
    if (code) {
      createIntegration({
        variables: {
          input: {
            provider: ZENDESK,
          },
          inputProvider: {
            redirectUri: ZENDESK_REDIRECT_URI,
            code,
            provider: ZENDESK,
          },
          companyId: selectedCompany
            ? parseInt(selectedCompany.value, 10)
            : undefined,
        },
      })
        .catch((error) => {
          console.error("Error Integrating Zendesk:", error);
        })
        .finally(() => {
          navigate(ZENDESK_INTEGRATIONS_PATH);
        });
    }
  }, []);

  // If code is not present redirect the user to integrations page without making request to BE
  return code ? (
    <LoadingAnimation text='Finalizing Integration...' />
  ) : (
    <Navigate to={ZENDESK_INTEGRATIONS_PATH} />
  );
};

export default IntegrationsZendesk;
