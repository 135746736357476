import { gql, useQuery } from "@apollo/client";
import Cookies from "js-cookie";

import Switch from "@mui/material/Switch";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import {
  FaBriefcaseMedical,
  FaBug,
  FaChartArea,
  FaRegLightbulb,
  FaBook,
  FaRegFile,
} from "react-icons/fa";
import { GrAnnounce } from "react-icons/gr";
import {
  TbDatabaseCog,
  TbLayoutSidebarLeftCollapse,
  TbLayoutSidebarRightCollapse,
  TbSql,
} from "react-icons/tb";
import {
  Menu,
  MenuItem,
  Sidebar,
  SubMenu,
  menuClasses,
} from "react-pro-sidebar";
import {
  Link,
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Select from "react-select";
// import "./App.css"; // Import the custom styles
import { FaCirclePlay } from "react-icons/fa6";

import client from "./apolloClient";
import BannerFromBackend from "./components/BannerFromBackend";
import Login from "./components/Login";
import { useAdmin } from "./contexts/AdminContext";
import { useAuth } from "./contexts/AuthContext";
import { useCompany } from "./contexts/CompanyContext";
import { UnsavedChangesProvider } from "./contexts/UnsavedChangesContext";
import LogoSquare from "./images/sailfishLogoSquareCropped.png";

import { BsGrid1X2Fill } from "react-icons/bs";
import { IoMdSettings } from "react-icons/io";

import { AiOutlineTable } from "react-icons/ai";
import {
  MdInsights,
  MdOutlineHistory,
  MdSavedSearch,
  MdSearch,
} from "react-icons/md";
import { RiDashboardHorizontalFill } from "react-icons/ri";
import AnnouncementsPage from "./pages/Announcements";
import ChartDashboardsPage from "./pages/ChartDashboards";
import ChartDataSourcesPage from "./pages/ChartDataSources";
import ChartGraphsPage from "./pages/ChartGraphs";
import ChartReportsPage from "./pages/ChartReports";
import { default as CreateAndEditDashboardPage } from "./pages/CreateAndEditDashboard";
import EmbeddedSupersetPage from "./pages/EmbeddedSuperset";
import FeatureRequests from "./pages/FeatureRequests";
import IndividualIssuePage from "./pages/IndividualIssue";
import IssuePage from "./pages/Issue";
import IssuesPage from "./pages/Issues";
import LogsPage from "./pages/Logs";
import LogsWithSearchAndExaminePage from "./pages/LogsWithSearchAndExamine";
import ReplaysWithSearchAndExaminePage from "./pages/ReplaysWithSearchAndExamine";
import Settings from "./pages/Settings";
import Transactions from "./pages/Transactions";
import TriagePage from "./pages/Triage";
import TriagesPage from "./pages/Triages";
import WelcomePage from "./pages/WelcomePage";
import { COOKIE_DOMAIN_OPTIONS } from "./utils/auth";

// // Lazy-loaded pages to keep chunks split per page, and MUCH smaller
// const AnnouncementsPage = lazy(() => import("./pages/Announcements"));
// const ChartDashboardsPage = lazy(() => import("./pages/ChartDashboards"));
// const ChartDataSourcesPage = lazy(() => import("./pages/ChartDataSources"));
// const ChartGraphsPage = lazy(() => import("./pages/ChartGraphs"));
// const ChartReportsPage = lazy(() => import("./pages/ChartReports"));
// const CreateAndEditDashboardPage = lazy(
//   () => import("./pages/CreateAndEditDashboard"),
// );
// const EmbeddedSupersetPage = lazy(() => import("./pages/EmbeddedSuperset"));
// const FeatureRequests = lazy(() => import("./pages/FeatureRequests"));
// const IndividualIssuePage = lazy(() => import("./pages/IndividualIssue"));
// const IssuePage = lazy(() => import("./pages/Issue"));
// const IssuesPage = lazy(() => import("./pages/Issues"));
// const LogsPage = lazy(() => import("./pages/Logs"));
// const LogsWithSearchAndExaminePage = lazy(
//   () => import("./pages/LogsWithSearchAndExamine"),
// );
// const ReplaysPage = lazy(() => import("./pages/Replays"));
// const ReplaysWithSearchAndExaminePage = lazy(
//   () => import("./pages/ReplaysWithSearchAndExamine"),
// );
// const Settings = lazy(() => import("./pages/Settings"));
// const Transactions = lazy(() => import("./pages/Transactions"));
// const TriagePage = lazy(() => import("./pages/Triage"));
// const TriagesPage = lazy(() => import("./pages/Triages"));
// const WelcomePage = lazy(() => import("./pages/WelcomePage"));

const GET_COMPANIES = gql`
  query GetCompanies {
    companies {
      id
      name
      domains
    }
  }
`;

const delete_me_generate_trigger_exception = (type: number) => {
  const queryName = `TriggerException${type}`;
  const queryNameLowercase =
    queryName.charAt(0).toLowerCase() + queryName.slice(1);

  return gql`
    query ${queryName}($id: String!) {
      ${queryNameLowercase}(id: $id)
    }
  `;
};

interface Company {
  id: string;
  name: string;
  domains: string[];
}

const App = () => {
  const [collapsed, setCollapsed] = useState(() => {
    const savedState = localStorage.getItem("sidebarCollapsed");
    return savedState ? JSON.parse(savedState) : false;
  });
  const [showLogout, setShowLogout] = useState(false); // State to manage logout button visibility
  const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });
  const location = useLocation();
  const {
    user,
    setUser,
    setAuthenticated,
    isSailfishQAMember,
    isAuthenticated,
    isTokenExpired,
  } = useAuth(); // Assume user includes { name, picture }
  const navigate = useNavigate();
  // const { loading, error, data } = useQuery(GET_COMPANIES, { client });
  const { data } = useQuery(GET_COMPANIES, {
    skip: !isAuthenticated || !isSailfishQAMember,
    client,
  });
  const { isAdminEnabled, setIsAdminEnabled } = useAdmin();
  const [prevSelectedCompany, setPrevSelectedCompany] = useState(() => {
    const storedCompany = localStorage.getItem("prevSelectedCompany");
    return storedCompany ? JSON.parse(storedCompany) : null;
  });

  const { selectedCompany, setSelectedCompany } = useCompany(); // Use useCompany hook
  const defaultCompany = useMemo(() => {
    const savedCompany = localStorage.getItem("selectedCompany");
    return savedCompany ? JSON.parse(savedCompany) : null;
  }, []);
  // For temp exceptions- DELETE
  const [loadingException, setLoadingException] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (!selectedCompany && isAdminEnabled && data && data.companies) {
      const userCompany = data.companies.find((company: any) =>
        company.domains.some((domain: string) =>
          user?.email?.endsWith(`@${domain}`),
        ),
      );
      const initialCompany =
        defaultCompany ||
        (userCompany
          ? { value: userCompany.id, label: userCompany.name }
          : null);
      if (initialCompany) {
        setSelectedCompany(initialCompany);
      }
    }
  }, [
    user,
    selectedCompany,
    isAdminEnabled,
    setSelectedCompany,
    defaultCompany,
    data,
  ]);

  const handleLogout = () => {
    Cookies.remove("jwt", COOKIE_DOMAIN_OPTIONS);
    localStorage.removeItem("user");
    setUser(null);
    setAuthenticated(false);
    navigate("/login");
  };

  const handleMouseEnter = (event: any) => {
    const rect = event.target.getBoundingClientRect();
    setTooltipPosition({
      left: rect.right, // Position right of the element
      top: rect.top + window.scrollY + rect.height / 2, // Vertically centered to the element
    });
    setShowLogout(true);
  };

  const handleMouseLeave = () => {
    setShowLogout(false);
  };

  const toggleSidebar = (e: SyntheticEvent) => {
    e.preventDefault();
    const newCollapsedState = !collapsed;
    setCollapsed(newCollapsedState);
    localStorage.setItem("sidebarCollapsed", JSON.stringify(newCollapsedState));
  };

  const handleCompanyChange = (selectedOption: any) => {
    setSelectedCompany(selectedOption);
    localStorage.setItem("selectedCompany", JSON.stringify(selectedOption));
  };

  const tooltip = (
    <div
      style={{
        position: "fixed",
        left: tooltipPosition.left,
        top: tooltipPosition.top,
        transform: "translateY(-50%)",
        backgroundColor: "white",
        boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
        borderRadius: "4px",
        paddingInline: "25px",
        paddingBlock: "10px",
        zIndex: 10000,
      }}
    >
      <button onClick={handleLogout} style={{ cursor: "pointer" }}>
        Logout
      </button>
    </div>
  );

  const sectionStyle = {
    padding: "0",
    width: "100%",
    overflow: "auto",
    zindex: 1,
  };

  const sectionInnerStyle = {
    padding: location.pathname === "/login" ? "0" : "20px",
    width: "100%",
    overflow: "auto",
    zindex: 1,
    height: "100%",
    position: "relative",
  } as const;

  const toggleIsAdminEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setIsAdminEnabled(isChecked);
    localStorage.setItem("isAdminEnabled", JSON.stringify(isChecked));

    if (!isChecked) {
      setPrevSelectedCompany(selectedCompany);
      localStorage.setItem(
        "prevSelectedCompany",
        JSON.stringify(selectedCompany),
      );
      setSelectedCompany(null);
    } else if (prevSelectedCompany) {
      setSelectedCompany(prevSelectedCompany);
    }
  };

  // // Default back to user's company when admin-enabled is false
  // const toggleIsSailfishQA = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const isChecked = event.target.checked;
  //   setIsSailfishQA(isChecked);
  //   localStorage.setItem("isSailfishQA", JSON.stringify(isChecked));

  //   if (!isChecked) {
  //     const userCompany = data?.companies.find((company: Company) =>
  //       user?.email?.endsWith(`@${company.domain}`),
  //     );
  //     if (userCompany && selectedCompany !== userCompany) {
  //       setPrevSelectedCompany(selectedCompany);
  //       localStorage.setItem(
  //         "prevSelectedCompany",
  //         JSON.stringify(selectedCompany),
  //       );
  //       setSelectedCompany({ value: userCompany.id, label: userCompany.name });
  //     }
  //   } else if (prevSelectedCompany) {
  //     setSelectedCompany(prevSelectedCompany);
  //   }
  // };

  // // useEffect to set initial state from localStorage
  // useEffect(() => {
  //   const storedIsSailfishQA = localStorage.getItem("isSailfishQA");
  //   if (storedIsSailfishQA !== null) {
  //     setIsSailfishQA(JSON.parse(storedIsSailfishQA));
  //   }

  //   if (!isSailfishQA) {
  //     const userCompany = data?.companies.find((company: Company) =>
  //       user?.email?.endsWith(`@${company.domain}`),
  //     );
  //     if (userCompany) {
  //       setSelectedCompany({ value: userCompany.id, label: userCompany.name });
  //     }
  //   }
  // }, [user, data, setSelectedCompany]);

  const handleTriggerException = async (exceptionId: number) => {
    const query = delete_me_generate_trigger_exception(exceptionId);
    try {
      setLoadingException(true);
      setErrorMessage(null);

      await client.query({
        query: query,
        variables: { id: "Ta&U-;3{PfwS@GyRyXuz{wQ_5;vM(2" },
      });

      console.log("Exception triggered successfully.");
    } catch (error) {
      console.error("Error triggering exception:", error);
      setErrorMessage("Failed to trigger exception. Please try again.");
    } finally {
      setLoadingException(false);
    }
  };

  // Don't render the page if the token is expired
  if (isTokenExpired && location.pathname !== "/login") {
    return null;
  }

  const menuItemRootStyles = {
    padding: "0 20px",
    marginBottom: 8,
    ["." + menuClasses.button]: {
      height: "32px !important",
      borderRadius: 8,
      padding: "0 !important",
    },
  };

  const subMenuRootStyles = {
    padding: "0 20px",
    ["." + menuClasses.button]: {
      height: "32px !important",
      borderRadius: 8,
      paddingLeft: "0 !important",
      ...(!collapsed && { marginBottom: 8 }),
    },
    ["." + menuClasses.subMenuContent]: {
      ...(collapsed
        ? {
            left: "20px !important",
            paddingTop: "8px !important",
          }
        : {
            backgroundColor: "unset",
          }),
    },
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        backgroundColor: "#f9fcfe",
        overflow: "hidden",
      }}
    >
      {location.pathname !== "/login" && (
        <Sidebar
          className='appSidebar text-sm font-medium bg-gradient-to-b from-sky-500 to-sky-400'
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflow: "visible",
          }}
          collapsed={collapsed}
          collapsedWidth='86px'
        >
          <div
            style={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            <Menu
              menuItemStyles={{
                button: {
                  "&:hover": {
                    backgroundColor: "#60a5fa",
                  },
                  "&.active": {
                    backgroundColor: "#295dbf",
                    color: "white",
                  },
                },
              }}
            >
              <MenuItem
                className='menu1'
                rootStyles={{
                  ["." + menuClasses.button]: {
                    height: "80px !important",
                    "&.active": {
                      backgroundColor: "rgb(249, 249, 249, 0.7)",
                      color: "black",
                    },
                    "&:hover": {
                      backgroundColor: "unset !important",
                    },
                  },
                }}
                component={<Link to='/' className='link' />}
                icon={
                  <img
                    src={LogoSquare}
                    width={40}
                    // onClick={toggleSidebar}
                    alt='Sailfish Logo'
                  />
                }
              >
                <div>
                  <h2 className='font-bold text-base'>Sailfish</h2>
                  <div
                    className={`cursor-pointer flex flex-col items-center absolute top-[30px] ${
                      collapsed ? "right-2" : "right-6"
                    }`}
                  >
                    <button
                      onClick={toggleSidebar}
                      className='flex justify-center'
                    >
                      {collapsed ? (
                        <TbLayoutSidebarRightCollapse size={20} />
                      ) : (
                        <TbLayoutSidebarLeftCollapse size={20} />
                      )}
                    </button>
                  </div>
                </div>
              </MenuItem>
              <MenuItem
                component={<NavLink to='/' className='link' />}
                icon={<BsGrid1X2Fill size={16} />}
                rootStyles={menuItemRootStyles}
              >
                Dashboard
              </MenuItem>
              {/* <MenuItem
                component={<NavLink to='replays-users' className='link' />}
                icon={<FaCirclePlay />}
              >
                Session Replays
              </MenuItem> */}
              {/* <MenuItem
                component={<NavLink to='logs' className='link' />}
                icon={<HiDocumentText />}
              >
                Logs
              </MenuItem> */}
              {/* <MenuItem
                component={<NavLink to='issues' className='link' />}
                icon={<IoBug />}
              >
                Issues
              </MenuItem> */}
              {/* Horizontal Divider Line */}
              {/* <div className='h-0.5 bg-gray-400 my-2 mx-[10%] rounded-full'></div> */}
              <MenuItem
                component={<NavLink to='recordings' className='link' />}
                icon={<FaCirclePlay size={20} />}
                rootStyles={menuItemRootStyles}
              >
                Recordings
              </MenuItem>
              <MenuItem
                component={<NavLink to='logs' className='link' />}
                icon={<FaRegFile size={20} />}
                rootStyles={menuItemRootStyles}
              >
                Logs
              </MenuItem>
              <MenuItem
                component={<NavLink to='triages' className='link' />}
                icon={<FaBriefcaseMedical size={20} />}
                rootStyles={menuItemRootStyles}
              >
                Triages
              </MenuItem>
              <MenuItem
                component={<NavLink to='issues' className='link' />}
                icon={<FaBug size={20} />}
                rootStyles={menuItemRootStyles}
              >
                Issues
              </MenuItem>
              <MenuItem
                component={
                  <NavLink
                    to='https://docs.sailfishqa.com/'
                    className='link'
                    target='_blank'
                  />
                }
                icon={<FaBook size={20} />}
                rootStyles={menuItemRootStyles}
              >
                Documentation
              </MenuItem>
              {isSailfishQAMember && (
                <SubMenu
                  label='Insights'
                  icon={<MdInsights size={20} />}
                  className='link'
                  rootStyles={subMenuRootStyles}
                >
                  <MenuItem
                    component={
                      <NavLink
                        to='insights/embedded/dashboard/list/'
                        end
                        className='link'
                      />
                    }
                    icon={<RiDashboardHorizontalFill size={26} />}
                    rootStyles={menuItemRootStyles}
                  >
                    Dashboards
                  </MenuItem>
                  <MenuItem
                    component={
                      <NavLink
                        to='insights/embedded/chart/list/'
                        end
                        className='link'
                      />
                    }
                    icon={<FaChartArea size={20} />}
                    rootStyles={menuItemRootStyles}
                  >
                    Charts
                  </MenuItem>
                  <MenuItem
                    component={
                      <NavLink
                        to='insights/embedded/tablemodelview/list/'
                        end
                        className='link'
                      />
                    }
                    icon={<AiOutlineTable size={26} />}
                    rootStyles={menuItemRootStyles}
                  >
                    Datasets
                  </MenuItem>

                  <SubMenu
                    label='SQL'
                    icon={<TbSql size={26} />}
                    className='link'
                    rootStyles={subMenuRootStyles}
                  >
                    <MenuItem
                      component={
                        <NavLink
                          to='insights/embedded/sqllab/'
                          end
                          className='link'
                        />
                      }
                      icon={<MdSearch size={26} />}
                      rootStyles={menuItemRootStyles}
                    >
                      SQL Lab
                    </MenuItem>
                    <MenuItem
                      component={
                        <NavLink
                          to='insights/embedded/savedqueryview/list/'
                          end
                          className='link'
                        />
                      }
                      icon={<MdSavedSearch size={26} />}
                      rootStyles={menuItemRootStyles}
                    >
                      Saved Qs
                    </MenuItem>
                    <MenuItem
                      component={
                        <NavLink
                          to='insights/embedded/sqllab/history/'
                          end
                          className='link'
                        />
                      }
                      icon={<MdOutlineHistory size={24} />}
                      rootStyles={menuItemRootStyles}
                    >
                      History
                    </MenuItem>
                  </SubMenu>
                  <MenuItem
                    component={
                      <NavLink
                        to='insights/embedded/databaseview/list/'
                        end
                        className='link'
                      />
                    }
                    icon={<TbDatabaseCog size={20} />}
                    rootStyles={menuItemRootStyles}
                  >
                    DB Settings
                  </MenuItem>
                </SubMenu>
              )}

              {/* <SubMenu label='Issues' icon={<IoBug />}>
                <MenuItem
                  component={<NavLink to='issues' className='link' />}
                  icon={<IoBug />}
                >
                  Issues
                </MenuItem>
                <MenuItem
                  component={<NavLink to='issue/1234' className='link' />}
                  icon={<IoBug />}
                >
                  Individual Issue
                </MenuItem>
              </SubMenu> */}
              {/* <SubMenu label='Testing' icon={<GrTest />}>
                <MenuItem
                  component={<NavLink to='experiments' className='link' />}
                  icon={<PiRepeatBold />}
                >
                  Persona Test Runs
                </MenuItem>
                <MenuItem
                  component={<NavLink to='experiments' className='link' />}
                  icon={<GrTrigger />}
                >
                  Manual Trigger
                </MenuItem>
              </SubMenu>
              <MenuItem
                component={<NavLink to='app-flows' className='link' />}
                icon={
                  <SvgIcon>
                    <SankeyIcon />
                  </SvgIcon>
                }
              >
                App Flows
              </MenuItem>
              <MenuItem
                component={<NavLink to='user-embeddings' className='link' />}
                icon={<Groups3Icon />}
              >
                User Embeddings
              </MenuItem>
              <SubMenu label='Replays' icon={<OndemandVideoIcon />}>
                <MenuItem
                  component={<NavLink to='replays-users' className='link' />}
                  icon={<BsPeopleFill />}
                >
                  Real Users
                </MenuItem>
              </SubMenu>
              <MenuItem
                component={<NavLink to='/alerts' className='link' />}
                icon={<NotificationsIcon />}
              >
                Alerts
              </MenuItem> */}
              <div className='h-0.5 bg-gray-400 my-2 mx-[10%] rounded-full'></div>
              <MenuItem
                component={<NavLink to='/feature-requests' className='link' />}
                icon={<FaRegLightbulb />}
                rootStyles={menuItemRootStyles}
              >
                Feature Requests
              </MenuItem>
              <MenuItem
                component={<NavLink to='/announcements' className='link' />}
                icon={<GrAnnounce />}
                rootStyles={menuItemRootStyles}
              >
                Announcements
              </MenuItem>
              <MenuItem
                component={<NavLink to='/settings' className='link' />}
                icon={<IoMdSettings />}
                rootStyles={{ ...menuItemRootStyles, marginBottom: 0 }}
              >
                Settings
              </MenuItem>
            </Menu>
            <div style={{ flex: 1 }}></div>
            {/* If debugging locally and you want to see how normal people see it, uncomment the button below */}
            {isSailfishQAMember && (
              <>
                {isAdminEnabled && (
                  <>
                    <div className='px-5 font-bold'>Selected Company</div>
                    <div className='px-5 pb-5 text-center relative'>
                      <Select
                        options={data?.companies.map((company: Company) => ({
                          value: company.id,
                          label: company.name,
                        }))}
                        onChange={handleCompanyChange}
                        placeholder='Select Company'
                        value={selectedCompany}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor:
                              selectedCompany &&
                              data?.companies.some(
                                (company: Company) =>
                                  company.domains.some((domain: string) =>
                                    user?.email?.endsWith(`@${domain}`),
                                  ) && company.id === selectedCompany.value,
                              )
                                ? provided.backgroundColor
                                : "orange",
                          }),
                        }}
                      />
                    </div>
                  </>
                )}
                <div className='flex items-center justify-center my-4 font-bold'>
                  <span className='mr-2'>Admin-enabled</span>
                  <Switch
                    checked={isAdminEnabled}
                    onChange={toggleIsAdminEnabled}
                    inputProps={{ "aria-label": "admin enabled switch" }}
                  />
                </div>
                {import.meta.env.DEV && (
                  <>
                    <button
                      className='bg-gray-100 m-1'
                      onClick={() => {
                        handleTriggerException(0);
                      }}
                      disabled={loadingException}
                    >
                      {loadingException ? "Loading..." : "Trigger Exception #0"}
                    </button>
                    <button
                      className='bg-gray-100 m-1'
                      onClick={() => {
                        handleTriggerException(1);
                      }}
                      disabled={loadingException}
                    >
                      {loadingException ? "Loading..." : "Trigger Exception #1"}
                    </button>
                    <button
                      className='bg-gray-100 m-1'
                      onClick={() => {
                        handleTriggerException(2);
                      }}
                      disabled={loadingException}
                    >
                      {loadingException ? "Loading..." : "Trigger Exception #2"}
                    </button>
                    <button
                      className='bg-gray-100 m-1'
                      onClick={() => {
                        handleTriggerException(3);
                      }}
                      disabled={loadingException}
                    >
                      {loadingException ? "Loading..." : "Trigger Exception #3"}
                    </button>
                    <button
                      className='bg-gray-100 m-1'
                      onClick={() => {
                        handleTriggerException(4);
                      }}
                      disabled={loadingException}
                    >
                      {loadingException ? "Loading..." : "Trigger Exception #4"}
                    </button>
                    {errorMessage && (
                      <div className='error-message'>{errorMessage}</div>
                    )}
                  </>
                )}
              </>
            )}
            {/* <button onClick={toggleIsSailfishQA} style={{ margin: "10px" }}>
              Toggle SailfishQA
            </button> */}
            {/* {isSailfishQA && (
              <div className='px-5 py-5 text-center relative'>
                <Select
                  options={data?.companies.map((company: Company) => ({
                    value: company.id,
                    label: company.name,
                  }))}
                  onChange={handleCompanyChange}
                  placeholder='Select Company'
                  value={selectedCompany}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor:
                        selectedCompany &&
                        data?.companies.some(
                          (company: Company) =>
                            user?.email?.endsWith(`@${company.domain}`) &&
                            company.id === selectedCompany.value,
                        )
                          ? provided.backgroundColor
                          : "orange",
                    }),
                  }}
                />
              </div>
            )} */}
            {/* <div style={{ flex: 1 }}></div> */}
            <div
              className={`mt-auto ${collapsed ? "px-0" : "px-5"} py-5 relative`}
            >
              {user && (
                <>
                  <div
                    className='cursor-pointer flex justify-center relative gap-2'
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <img
                      src={user.picture}
                      alt='User'
                      className='w-10 h-10 rounded-xl'
                    />
                    {!collapsed && (
                      <div className='overflow-hidden text-ellipsis whitespace-nowrap'>
                        <div className='text-sm font-normal'>{user.name}</div>
                        <div className='text-xs leading-5 font-medium'>
                          {user.email}
                        </div>
                      </div>
                    )}
                    {/* <div>Title (placeholder)</div> */}

                    {showLogout &&
                      ReactDOM.createPortal(
                        tooltip,
                        document.getElementById("portal-root") ||
                          document.createElement("div"), // Fallback to a new div if null
                      )}
                  </div>
                </>
              )}
            </div>
          </div>
        </Sidebar>
      )}
      <section style={sectionStyle}>
        {location.pathname !== "/login" && <BannerFromBackend />}
        <section id='mainAppPagesSection' style={sectionInnerStyle}>
          <Routes>
            {/* <Route path='/' element={<Dashboard />} /> */}
            <Route path='/' element={<WelcomePage />} />
            <Route path='login' element={<Login />} />
            <Route path='experiments' element={<Transactions />} />
            <Route
              path='recordings/:recordingId?'
              element={<ReplaysWithSearchAndExaminePage />}
            />
            <Route
              path='logs/:logId?'
              element={<LogsWithSearchAndExaminePage />}
            />
            <Route
              path='insights/dashboards'
              element={<ChartDashboardsPage />}
            />
            <Route path='insights/reports' element={<ChartReportsPage />} />
            <Route path='insights/graphs' element={<ChartGraphsPage />} />
            <Route
              path='insights/data-sources'
              element={<ChartDataSourcesPage />}
            />
            <Route
              path='insights/embedded/*'
              element={<EmbeddedSupersetPage />}
            />
            <Route
              path='insights/dashboards/create-new?'
              element={<CreateAndEditDashboardPage />}
            />
            <Route
              path='insights/dashboards/:dashboardId?'
              element={<CreateAndEditDashboardPage />}
            />
            <Route path='issues?' element={<IssuesPage />} />
            <Route path='issues/:issueId?' element={<IssuePage />} />
            <Route path='triage/:triageId?' element={<TriagePage />} />
            <Route path='logs/:recordingId?' element={<LogsPage />} />
            <Route path='triages' element={<TriagesPage />} />
            <Route path='issues' element={<IssuesPage />} />
            <Route path='issue/:issueId?' element={<IndividualIssuePage />} />
            <Route path='feature-requests' element={<FeatureRequests />} />
            <Route
              path='settings/*'
              element={
                <UnsavedChangesProvider>
                  <Settings />
                </UnsavedChangesProvider>
              }
            />
            <Route path='transactions' element={<Transactions />} />
            <Route path='/announcements' element={<AnnouncementsPage />} />
          </Routes>
        </section>
      </section>
    </div>
  );
};
export default App;
