import React, { useEffect, useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";

const textSequence = [
  { text: "Collecting recording...", duration: 1000 },
  { text: "Compiling logs...", duration: 3000 },
  { text: "Stitching data...", duration: 1000 },
  { text: "Analyzing & comparing to all sessions...", duration: 8000 },
  { text: "Building data packet...", duration: 2000 },
  { text: "Finalizing...", duration: 1000 },
];

const TriageLoadingScreen: React.FC<{
  onComplete: () => void;
  animationOffset: number;
}> = ({ onComplete, animationOffset }) => {
  const [currentText, setCurrentText] = useState("Collecting recording...");
  const [fadeOut, setFadeOut] = useState(false);

  const totalAnimationDuration = textSequence.reduce(
    (acc, { duration }) => acc + duration,
    0,
  );

  useEffect(() => {
    // If the animationOffset is beyond the total duration, skip to onComplete
    if (animationOffset >= totalAnimationDuration) {
      onComplete();
      return;
    }

    // Adjust the sequence based on the animationOffset
    let totalDuration = 0;
    let remainingDuration = animationOffset;

    textSequence.forEach(({ text, duration }) => {
      if (remainingDuration >= duration) {
        remainingDuration -= duration;
        totalDuration += duration;
      } else {
        const timeout = setTimeout(() => {
          setCurrentText(text);
        }, totalDuration - animationOffset);
        totalDuration += duration;

        // Cleanup previous timeouts when component unmounts
        return () => clearTimeout(timeout);
      }
    });

    const fadeOutTimeout = setTimeout(() => {
      setFadeOut(true);
      const completeTimeout = setTimeout(() => {
        onComplete();
      }, 1000); // Duration of the fade-out effect

      // Cleanup complete timeout
      return () => clearTimeout(completeTimeout);
    }, totalDuration - animationOffset);

    // Cleanup fadeOut timeout when component unmounts
    return () => clearTimeout(fadeOutTimeout);
  }, [animationOffset, totalAnimationDuration, onComplete]); // Reduced dependency list

  if (animationOffset >= totalAnimationDuration) {
    return null; // Return null when animation is complete to avoid rendering an empty fragment
  }

  return (
    <div
      className={`fixed inset-0 flex flex-col items-center justify-center transition-opacity duration-1000 ${
        fadeOut ? "opacity-0" : "opacity-100"
      } z-50 bg-white`}
    >
      <PuffLoader size={100} color='#47a7fc' speedMultiplier={1} />
      <p className='mt-4 text-lg font-medium'>{currentText}</p>
    </div>
  );
};

export default TriageLoadingScreen;
