import React from "react";

interface ContextMenuItem {
  label: string;
  action: () => void;
  disabled: boolean;
}

interface ContextAltClickMenuProps {
  position: { x: number; y: number };
  menuItems: ContextMenuItem[];
  onClose: () => void;
}

const ContextAltClickMenu: React.FC<ContextAltClickMenuProps> = ({
  position,
  menuItems,
  onClose,
}) => {
  return (
    <div
      style={{
        position: "absolute",
        top: position.y,
        left: position.x,
        backgroundColor: "white",
        border: "1px solid black",
        zIndex: 10000,
      }}
      onMouseLeave={onClose} // Close the menu if the mouse leaves the menu area
    >
      {menuItems.map((item, index) => (
        <div
          key={index}
          onClick={() => {
            if (!item.disabled) {
              item.action();
              onClose(); // Close the menu after an action is selected
            }
          }}
          className={`px-3 py-2 cursor-pointer ${
            item.disabled
              ? "bg-gray-100 text-gray-400 cursor-not-allowed"
              : "hover:bg-primary-light"
          }`}
          role='button'
          tabIndex={0}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
};

export default ContextAltClickMenu;
