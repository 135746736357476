import { ApolloProvider } from "@apollo/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { startRecording } from "@sailfish/recorder";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "video.js/dist/video-js.css";
import App from "./App";
import NavigationListener from "./NavigationListener";
import client from "./apolloClient";
import { AdminProvider } from "./contexts/AdminContext";
import { AuthProvider } from "./contexts/AuthContext"; // Ensure the path to AuthContext is correct
import { CompanyProvider } from "./contexts/CompanyContext";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import WebSocketService from "./utils/websocket";
import ZendeskWidget from "./components/ZendeskChat";

const backendApi = import.meta.env.VITE_BACKEND_API;
const apiKey = import.meta.env.VITE_API_KEY;

startRecording({
  apiKey: import.meta.env.VITE_API_KEY,
  backendApi: import.meta.env.VITE_BACKEND_API, // Was used when experimenting with nginx forwarding
  domainsToNotPropagateHeaderTo: [
    "https://sailfishai863.zendesk.com",
    "https://ekr.zdassets.com/",
  ],
});

// Start the WebSocket connection for the entire application
WebSocketService.connect("sailfish-ui", backendApi, apiKey);

const googleClientId =
  "750193212835-cjq1bsveacmqoa1a9mdc9lde874gpuc0.apps.googleusercontent.com";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  // <React.StrictMode>
  <div>
    <BrowserRouter>
      <NavigationListener />
      <GoogleOAuthProvider clientId={googleClientId}>
        <ApolloProvider client={client}>
          <AuthProvider>
            <CompanyProvider>
              <AdminProvider>
                <App />
                <ZendeskWidget/>
              </AdminProvider>
            </CompanyProvider>
          </AuthProvider>
        </ApolloProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  </div>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
